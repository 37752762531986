import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SingleData, UserData } from '../models/data';
import { PostUser, User, UserList } from '../models/user';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient) {}

  //get all users
  getUsers(pageSize: number, pageNumber: number) {
    return this.http.get<UserList>(
      environment.userApis + '/users?' + `page=${pageNumber}&size=${pageSize}`
    );
  }

  //get user data by id
  getUserById(userId: string) {
    return this.http.get<User>(environment.userApis + '/users/' + userId);
  }

  //get user data by search
  getUserBySearch(searchValue: string) {
    return this.http.get<UserList>(
      environment.userApis + '/users?search=' + searchValue
    );
  }

  //get user roles by user id
  getUserRolesById(userId: string) {
    return this.http.get<any>(
      environment.userApis + '/users/' + userId + '/roles'
    );
  }

  //get user Group by user id
  getUserGroupById(userId: string) {
    return this.http.get<any>(
      environment.userApis + '/users/' + userId + '/groups'
    );
  }

  //create new user
  createNewUser(user: any) {
    return this.http.post<User>(environment.userApis + '/users', user);
  }

  //edit user data
  editUserData(userId: string, user: PostUser) {
    return this.http.put<User>(environment.userApis + '/users/' + userId, user);
  }

  //delete user by id
  deleteUserData(userId: string) {
    return this.http.delete<User[]>(environment.userApis + '/users/' + userId);
  }

  addGroupToUser(userId: string, groupId: string) {
    return this.http.post<any>(
      environment.userApis + '/users/' + userId + '/groups/' + groupId,
      {}
    );
  }

  assignRolesToUser(userId: string, roles: any[]) {
    return this.http.post<any>(
      environment.userApis + '/users/' + userId + '/roles',
      roles
    );
  }

  deleteRoleFromUser(userId: string, role: any) {
    return this.http.delete<any>(
      environment.userApis + '/users/' + userId + '/roles',
      {
        body: role,
      }
    );
  }

  addRoleToUser(userId: string, roles: any) {
    return this.http.post<any>(
      environment.userApis + '/users/' + userId + '/roles',
      { roles }
    );
  }

  deleteUserFromGroup(userId: string, groupId: string) {
    return this.http.delete<any>(
      environment.userApis + '/users/' + userId + '/groups/' + groupId
    );
  }

  updateUserPassword(userId: string, password: string) {
    return this.http.put<any>(
      environment.userApis + '/users/' + userId + '/reset-password',
      {
        temporary: false,
        value: password,
      }
    );
  }

  uploadUserXlsx(file: any) {
    return this.http.post<any>(
      environment.userApis + '/users/upload',
      file
    );
  }

  //old apis

  // headers = { 'content-type': 'application/vnd.api+json', 'accept': 'application/vnd.api+json' };

  // public getUsers(params: HttpParams): Observable<any> {
  //   return this.http.get<any>("https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/users", { params: params, headers: this.headers });
  // }
  // public createUser(userData: SingleData): Observable<any> {
  //   return this.http.post<any>("https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/users", userData, { headers: this.headers });
  // }
  // public updateUser(id: string, userData: SingleData): Observable<any> {
  //   return this.http.patch<any>(`https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/users/${id}`, userData, { headers: this.headers });
  // }
  // public deleteUser(id: string): Observable<any> {
  //   return this.http.delete<any>(`https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/users/${id}`);
  // }

  exportUsers(groupId: string) {
    return this.http.get<any>(
      environment.userApis + `/groups/${groupId}/export-users`
    );
  }
}
