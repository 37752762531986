<section class="p-3">
  <div class="d-flex justify-content-between mb-3">
    <div>
      <button
        nz-button
        nzSize="large"
        nzType="default"
        (click)="createNews()"
        class="align-items-center d-flex addButton h-100"
      >
        {{ "news.add" | translate }}
        <span nz-icon nzType="group" nzTheme="outline"></span>
      </button>
    </div>
    <div>
      <ng-template #suffixIconButton>
        <button nz-button nzType="primary" nzSearch>
          <span nz-icon nzType="search"></span>
        </button>
      </ng-template>
      <nz-input-group
        nzSearch
        [nzAddOnAfter]="suffixIconButton"
        [nzSuffix]="suffixIcon"
      >
        <input
          type="text"
          nz-input
          placeholder="{{ 'news.search' | translate }}"
          [(ngModel)]="searchvalue"
          (ngModelChange)="searchResult()"
        />
      </nz-input-group>
      <ng-template #suffixIcon>
        <i
          nz-icon
          nzType="close-circle"
          *ngIf="searchvalue?.length"
          (click)="clearSearch()"
        ></i>
      </ng-template>
    </div>
  </div>
  <nz-table
    #basicTable
    [nzData]="news"
    [nzFrontPagination]="false"
    [nzScroll]="{ x: 'auto' }"
  >
    <thead>
      <tr>
        <th>{{ "app.table.image" | translate }}</th>
        <th>{{ "app.table.name" | translate }}</th>
        <th>{{ "app.table.description" | translate }}</th>
        <th nzWidth="100px">{{ "app.table.action" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data" style="cursor: pointer">
        <td>
          <!-- <a target="_blank">
            <img
              [src]="data.imageBase64 | transformImage"
              [alt]="data.imageBase64.name"
              class="app-img"
              height="50px"
              [title]="data.imageBase64.name"
            />
          </a> -->
        </td>
        <td>
          <span [innerHTML]="highlightText(data.title,searchvalue)"></span>
        </td>
        <td>
          <span> {{data.description}} </span>
        </td>
        <td>
          <a
            appClickPropagation
            nz-icon
            nzType="edit"
            nzTheme="outline"
            (click)="editNews(data)"
          ></a>
          <nz-divider nzType="vertical"></nz-divider>
          <a
            appClickPropagation
            nz-icon
            nzType="delete"
            nzTheme="outline"
            nz-popconfirm
            [nzPopconfirmTitle]="'app.drawer.areYouSure' | translate"
            [nzOkText]="'app.drawer.ok' | translate"
            [nzCancelText]="'app.drawer.cancel' | translate"
            class="text-danger"
            (nzOnConfirm)="confirm(data.id)"
            (nzOnCancel)="cancel()"
          ></a>
        </td>
      </tr>
    </tbody>
  </nz-table>
</section>

<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]="false"
  [nzWidth]="500"
  [nzVisible]="visible"
  [nzTitle]="'app.drawer.title' | translate"
  [nzFooter]="footerTpl"
  (nzOnClose)="close()"
>
  <form
    *nzDrawerContent
    nz-form
    [formGroup]="newsForm"
    (ngSubmit)="submit()"
  >

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="title">
        {{ "news.tilte" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'app.drawer.nameError' | translate"
      >
        <input nz-input formControlName="title" id="title" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="description">
        {{ "app.drawer.description" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'app.drawer.descriptionError' | translate"
      >
        <input nz-input formControlName="description" id="description" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="image">
        {{ "app.drawer.image" | translate }}
      </nz-form-label>
      <!-- <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'app.drawer.imageError' | translate"
      >
        <div class="clearfix">
          <nz-upload
            class="upload-list-inline"
            nzAction="https://jsonplaceholder.typicode.com/posts/"
            nzListType="picture"
            [nzFileList]="fileList"
            (nzChange)="handleChange($event)"
            [nzPreview]="handleDownload"
          >
            <button nz-button type="button">
              <span nz-icon nzType="upload"></span>
              {{ "app.drawer.upload" | translate }}
            </button>
          </nz-upload>
        </div>
      </nz-form-control> -->
    </nz-form-item>
  </form>

  <ng-template #footerTpl>
    <div style="float: right">
      <button nz-button style="margin-right: 8px" (click)="close()">
        {{ "app.drawer.cancel" | translate }}
      </button>
      <button nz-button nzType="primary" (click)="submit()">
        {{ "app.drawer.submit" | translate }}
      </button>
    </div>
  </ng-template>
</nz-drawer>
