import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { KeycloakAdminService } from '../../../services/keycloak-admin.service';
import { TranslateModule } from '@ngx-translate/core';
import { LangService } from '../../../services/lang.service';
import { GroupsService } from '../../../services/groups.service';
import { RolesService } from '../../../services/roles.service';

@Component({
  selector: 'app-edit-group',
  standalone: true,
  imports: [
    TranslateModule,
    NzTransferModule,
    NzTableModule,
    NzSwitchModule,
    CommonModule,
  ],
  templateUrl: './edit-group.component.html',
  styleUrl: './edit-group.component.css',
})
export class EditGroupComponent implements OnInit {
  @Input() id: any;
  realmRoles: any;
  groupRoles: any;
  combinedRoles: any[] = [];
  targetKeys: any[] = [];
  disabled!: boolean;
  group: any;

  currentPage: number = 1;
  pageSize: number = 10;
  totalItems: number = 0;

  constructor(
    private message: NzMessageService,
    private langService: LangService,
    private groupService: GroupsService,
    private rolesService: RolesService
  ) {}

  async ngOnInit() {
    // console.log(this.id);
    if (this.id) {
      this.getGroupData(this.id);
      this.getRealmRoles(1, 10);
    }
  }

  //get group data
  getGroupData(id: string) {
    this.groupService.getGroupById(id).subscribe({
      next: (data) => {
        this.group = data;
      },
    });
  }

  //get all realm role
  getRealmRoles(currentPage: number, pageSize: number) {
    this.rolesService.getRoles(pageSize, currentPage).subscribe({
      next: (data) => {
        this.realmRoles = data.items;
        this.totalItems = data.totalCount;
        console.log(this.realmRoles);
        
      },
      complete: () => {
        this.getGroupRoles(this.id);
      },
    });
  }

  //get group's roles
  getGroupRoles(id: string) {
    this.groupService.getGroupRoles(id).subscribe({
      next: (data) => {
        this.groupRoles = data;
      },
      complete: () => {
        this.handelTrasfareDirections();
      },
    });
  }

  //handel trasnfare data and directions for rolles
  handelTrasfareDirections() {
    const groupRoleIds = new Set(this.groupRoles?.map((role: any) => role.id));

    const filteredRealmRoles = this.realmRoles.filter(
      (role: any) => !groupRoleIds.has(role.id)
    );
    // console.log(this.groupRoles);

    // Combine groupRoles and filteredRealmRoles
    if (this.groupRoles?.length > 0) {
      this.combinedRoles = [
        ...this.groupRoles?.map((role: any) => ({
          id: role.id,
          name: role.name,
          direction: 'right',
        })),
        ...filteredRealmRoles.map((role: any) => ({
          id: role.id,
          name: role.name,
          direction: 'left',
        })),
      ];
    } else {
      this.combinedRoles = [
        ...filteredRealmRoles.map((role: any) => ({
          id: role.id,
          name: role.name,
          direction: 'left',
        })),
      ];
    }

    this.targetKeys = this.combinedRoles
      .filter((role: any) => role.direction === 'right')
      .map((role: any) => role.key);
  }

  //on transfare data
  onChangeRole(event: any): void {
    if (event?.list?.length == 0) return;
    this.disabled = true;
    if (event.from == 'right') {
      //delete role
      const newArray: any[] = [];
      event?.list.forEach((data: any) => {
        newArray.push({
          id: data.id,
          name: data.name,
        });
      });
      newArray.forEach((data: any) => {
        this.groupService.deleteRoleFromGroup(this.id, data).subscribe({
          next: (res) => {
            this.createMessage(
              this.langService.lang == 'en'
                ? `The roles ${data.name} have been successfully removed from the group`
                : ` Die Rollen ${data.name} wurden erfolgreich aus der Gruppe entfernt`,
              'success'
            );
            this.combinedRoles.forEach((data: any, index: number) => {
              this.combinedRoles[index].disapled = false;
              // this.combinedGroups[index].checked = true;
            });
          },
          complete: () => {
            this.disabled = false;
          },
        });
      });
    } else {
      //add role
      const newArray: any[] = [];
      event?.list.forEach((data: any) => {
        newArray.push({
          id: data.id,
          name: data.name,
        });
      });
      // console.log(newArray, this.id);
      newArray.forEach((data: any) => {
        this.groupService.addRoleToGroup(this.id, data).subscribe({
          next: (res) => {
            this.createMessage(
              this.langService.lang == 'en'
                ? `The roles ${data.name} have been successfully added to the group`
                : `Die Rollen ${data.name} wurden erfolgreich zur Gruppe hinzugefügt`,
              'success'
            );
            this.combinedRoles.forEach((data: any, index: number) => {
              this.combinedRoles[index].disapled = false;
              // this.combinedGroups[index].checked = true;
            });
          },
          complete: () => {
            this.disabled = false;
          },
        });
      });
    }
  }
  //create message
  createMessage(message: string, type: string): void {
    this.message.create(type, `${message}`);
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.getRealmRoles(this.currentPage, this.pageSize);
  }
}
