<!-- Roles component -->
<section class="p-3">
  <div class="d-flex justify-content-between mb-3">
    <div>
      <button
        nz-button
        nzSize="large"
        nzType="default"
        (click)="createNewRole()"
        class="align-items-center d-flex addButton h-100"
      >
        {{ "roles.addRole" | translate }}
        <span nz-icon nzType="appstore-add" nzTheme="outline"></span>
      </button>
    </div>
    <div>
      <ng-template #suffixIconButton>
        <button nz-button nzType="primary" nzSearch>
          <span nz-icon nzType="search"></span>
        </button>
      </ng-template>

      <nz-input-group
        nzSearch
        [nzAddOnAfter]="suffixIconButton"
        [nzSuffix]="suffixIcon"
      >
        <input
          type="text"
          nz-input
          placeholder="{{ 'roles.searchPlaceholder' | translate }}"
          #searchValue
          [(ngModel)]="searchText"
          (input)="searchResult()"
        />
      </nz-input-group>
      <ng-template #suffixIcon>
        <i
          nz-icon
          nzType="close-circle"
          *ngIf="searchValue.value"
          (click)="clearSearch()"
        ></i>
      </ng-template>
    </div>
  </div>
  <nz-table
    #basicTable
    [nzData]="rolesData"
    *ngIf="rolesData"
    [nzFrontPagination]="false"
    [nzTotal]="totalCount"
    [nzPageSize]="pageSize"
    [nzPageIndex]="pageNumber"
    (nzPageIndexChange)="onPageIndexChange($event)"
    (nzPageSizeChange)="onPageSizeChange($event)"
    [nzScroll]="{ x: 'auto' }"
  >
    <thead>
      <tr>
        <th>{{ "roles.tableHeaders.name" | translate }}</th>
        <th>{{ "roles.tableHeaders.description" | translate }}</th>
        <th>{{ "roles.tableHeaders.action" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td>
          <span [innerHTML]="highlightText(data.name, searchText)"></span>
        </td>
        <td>
          <span
            [innerHTML]="highlightText(data.description, searchText)"
          ></span>
        </td>
        <td>
          <a
            nz-icon
            nzType="edit"
            nzTheme="outline"
            (click)="editRole(data)"
          ></a>
          <nz-divider nzType="vertical"></nz-divider>
          <a
            nz-icon
            nzType="delete"
            nzTheme="outline"
            nz-popconfirm
            [nzPopconfirmTitle]="'roles.confirmDelete' | translate"
            [nzOkText]="'roles.yes' | translate"
            [nzCancelText]="'roles.cancel' | translate"
            class="text-danger"
            (nzOnConfirm)="confirm(data.name)"
            (nzOnCancel)="cancel()"
          ></a>
        </td>
      </tr>
    </tbody>
  </nz-table>
</section>
<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]="false"
  [nzWidth]="500"
  [nzVisible]="visible"
  [nzTitle]="isUpdate ? 'Edit Role' : 'new Role'"
  [nzFooter]="footerTpl"
  (nzOnClose)="close()"
>
  <form
    *nzDrawerContent
    nz-form
    [formGroup]="newRoleForm"
    (ngSubmit)="createRole()"
  >
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{
        "roles.name" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="
          newRoleForm.get('name')?.invalid &&
          (newRoleForm.get('name')?.touched || newRoleForm.get('name')?.dirty)
            ? ('roles.nameRequired' | translate)
            : null
        "
      >
        <input nz-input formControlName="name" id="name" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="description">{{
        "roles.description" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="
          newRoleForm.get('description')?.invalid &&
          (newRoleForm.get('description')?.touched ||
            newRoleForm.get('description')?.dirty)
            ? ('roles.descriptionRequired' | translate)
            : null
        "
      >
        <input nz-input formControlName="description" id="description" />
      </nz-form-control>
    </nz-form-item>
  </form>

  <ng-template #footerTpl>
    <div style="float: right">
      <button nz-button style="margin-right: 8px" (click)="close()">
        {{ "roles.cancel" | translate }}
      </button>
      <button nz-button nzType="primary" (click)="createRole()">
        {{ "roles.submit" | translate }}
      </button>
    </div>
  </ng-template>
</nz-drawer>
