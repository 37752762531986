import { Injectable, inject } from '@angular/core';
import { SingleData } from '../models/data';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IRole, RolesList } from '../models/roles';
import { environment } from '../../environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private http = inject(HttpClient);

  // get all roles

  getAllRoles() {
    return this.http.get<IRole[]>(environment.userApis + '/roles/all');
  }
  getRoles(size: number, page: number) {
    return this.http.get<RolesList>(
      environment.userApis + '/roles' + '?size=' + size + '&page=' + page
    );
  }

  getRolesBySearch(searchValue: string) {
    return this.http.get<RolesList>(
      environment.userApis + '/roles' + '?search=' + searchValue
    );
  }

  // Add new role
  addNewRole(role: any) {
    return this.http.post(environment.userApis + '/roles', role);
  }

  //delete roles
  deleteRoleByName(name: string) {
    return this.http.delete(environment.userApis + '/roles/' + name);
  }

  //update role data
  updateRoleData(name: string, role: any) {
    return this.http.put(environment.userApis + '/roles/' + name, role);
  }

  /////////old apis
  // headers = { 'content-type': 'application/vnd.api+json', 'accept': 'application/vnd.api+json' };

  // public getRoles(params?: HttpParams): Observable<any> {
  //   return this.http.get<any>("https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/roles", { params: params, headers: this.headers });
  // }
  // public createRole(roleData: SingleData): Observable<any> {
  //   return this.http.post<any>("https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/roles", roleData, { headers: this.headers });
  // }
  // public updateRole(id: string, roleData: SingleData): Observable<any> {
  //   return this.http.patch<any>(`https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/roles/${id}`, roleData, { headers: this.headers });
  // }
  // public deleteRole(id: string): Observable<any> {
  //   return this.http.delete<any>(`https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/roles/${id}`);
  // }
}
