import { Component, ElementRef, OnInit, inject } from '@angular/core';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { CommonModule, NgFor } from '@angular/common';
import { UsersService } from '../../services/users.service';
import {
  Attributes,
  SingleData,
  UserAttributes,
  UserData,
} from '../../models/data';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import * as XLSX from 'xlsx';

import {
  FormControl,
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { filterFormValues } from '../../models/form-utility';
import { RouterLink } from '@angular/router';
import { ClickPropagationDirective } from '../../directives/click-propagation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { LangService } from '../../services/lang.service';
import { User } from '../../models/user';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { NzModalModule } from 'ng-zorro-antd/modal';
type AOA = any[][];

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    CommonModule,
    NzTableModule,
    NzDividerModule,
    NgFor,
    NzAffixModule,
    NzButtonModule,
    NzIconModule,
    NzFlexModule,
    NzDrawerModule,
    NzFormModule,
    FormsModule,
    NzInputModule,
    NzSwitchModule,
    NzSelectModule,
    NzMessageModule,
    NzPopconfirmModule,
    ReactiveFormsModule,
    RouterLink,
    ClickPropagationDirective,
    TranslateModule,
    AgGridAngular,
    NzModalModule,
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.css',
})
export class UsersComponent implements OnInit {
  userService = inject(UsersService);
  langService = inject(LangService);
  data: any;
  headData: any;
  selectedFile: File | null = null;
  columnDefs: ColDef[] = [];
  rowData: any[] = [];
  previewFile: boolean = false;
  isUpdate: boolean = false;
  openedUserId: string = '';
  drawerTitle: string = 'New Form';
  searchText: any;
  allUsers: User[] = [];
  users: User[] = [];
  usersData: any = {};
  visible = false;
  attributes = {} as Attributes;
  statusFilters = [
    { text: 'Active', value: true },
    { text: 'Inactive', value: false },
    { text: 'All', value: 'all' },
  ];
  pageSize: number = 10;
  pageNumber: number = 1;
  total!: number;

  constructor(
    private fb: NonNullableFormBuilder,
    private message: NzMessageService
  ) {}

  ngOnInit() {
    this.getUsers();
  }

  //=========================all get users and pagination handle ===============================
  getUsers() {
    this.userService.getUsers(this.pageSize, this.pageNumber).subscribe({
      next: (data) => {
        this.allUsers = data.items;
        this.users = data.items;
        this.total = data.totalCount;
      },
      error(err) {
        console.log(err);
      },
    });
  }

  onPageIndexChange(page: number): void {
    this.pageNumber = page;
    this.getUsers();
  }

  onPageSizeChange(size: number): void {
    this.pageSize = size;
    this.pageNumber = 1; // reset to first page
    this.getUsers();
  }

  //===================== implement user form =================

  newUserForm: FormGroup<{
    email: FormControl<string>;
    username: FormControl<string>;
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    enabled: FormControl<boolean>;
    password: FormControl<string>;
    otp: FormControl<boolean>;
  }> = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
    username: ['', [Validators.required]],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    password: ['', [Validators.required]],
    enabled: [true],
    otp: [false],
  });

  mapFormToUserAttributes(): any {
    return {
      username: this.newUserForm.get('username')?.value,
      firstName: this.newUserForm.get('firstName')?.value,
      lastName: this.newUserForm.get('lastName')?.value,
      email: this.newUserForm.get('email')?.value,
      enabled: this.newUserForm.get('enabled')?.value,
      otp: this.newUserForm.get('otp')?.value,
    };
  }

  // ===================== open create and edit user =============================
  createNewUser() {
    this.isUpdate = false;
    this.drawerTitle = 'New user';
    this.newUserForm.reset();
    this.newUserForm.controls['username'].enable();
    this.open();
  }
  editUser(data: any) {
    this.drawerTitle = 'Edit user';
    this.newUserForm.reset();
    this.newUserForm.controls['username'].disable();
    this.isUpdate = true;
    this.open();
    this.openedUserId = data.id;
    try {
      const filtered = filterFormValues(this.newUserForm, data);
      console.log(data, filtered);

      filtered.password = 'dummy';
      this.newUserForm.setValue(filtered);
    } catch (e) {
      console.error(e);
    }
  }

  cancel() {}

  // =====================delete user ========================
  confirm(id: string) {
    this.userService.deleteUserData(id).subscribe({
      next: (data) => {
        this.createMessage(
          this.langService.lang == 'en'
            ? 'User has been deleted'
            : 'Benutzer wurde gelöscht',
          'success'
        );
        this.getUsers();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  //==================== add / edit user =====================
  createUser() {
    let user: any = this.mapFormToUserAttributes();

    //edit data for selected user
    if (this.isUpdate) {
      console.log(user);

      this.userService.editUserData(this.openedUserId, user).subscribe({
        next: (data) => {
          this.createMessage('User has been updated', 'success');
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
          this.getUsers();
          this.newUserForm.reset();
          this.close();
        },
      });
    }

    //add new user
    else {
      if (!this.newUserForm.valid) {
        this.newUserForm.markAllAsTouched();
        return;
      }
      console.log(this.newUserForm.value);

      // user.credentials = [
      //   { value: this.newUserForm.get('password')?.value, temporary: true },
      // ];
      // user.requiredActions = ['UPDATE_PASSWORD'];
      this.userService.createNewUser(this.newUserForm.value).subscribe({
        next: (data) => {
          this.createMessage('User has been created', 'success');
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
          this.getUsers();
          this.newUserForm.reset();
          this.close();
        },
      });
    }
  }

  createMessage(message: string, type: string): void {
    this.message.create(type, `${message}`);
  }

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  //============================search and filter operations===================================
  filterByStatus = (status: any) => {
    if (status == null) this.users = this.allUsers;
    else if (status != 'all') {
      this.users = this.allUsers.filter((user: any) => {
        return user.enabled == status;
      });
    } else {
      this.users = this.allUsers;
    }
  };

  searchResult() {
    if (this.searchText?.length) {
      // this.users = this.allUsers.filter((user: any) => {
      //   return (
      //     user.username
      //       ?.toLowerCase()
      //       .includes(this.searchText.toLowerCase()) ||
      //     user.firstName
      //       ?.toLowerCase()
      //       .includes(this.searchText.toLowerCase()) ||
      //     user.lastName
      //       ?.toLowerCase()
      //       .includes(this.searchText.toLowerCase()) ||
      //     user.email?.toLowerCase().includes(this.searchText.toLowerCase())
      //   );
      // });
      this.userService.getUserBySearch(this.searchText).subscribe({
        next: (data) => {
          this.users = data.items;
          this.total = data.totalCount;
        },
      });
    }
  }

  clearSearch() {
    this.searchText = '';
    this.getUsers();
  }

  highlightText(text: string, search: string): string {
    if (!search) {
      return text;
    }
    const re = new RegExp(search, 'gi');
    return text?.replace(
      re,
      (match) => `<span class="highlight">${match}</span>`
    );
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0]; // Get the selected file
    // if (this.selectedFile) {
    //   const formData = new FormData();
    //   formData.append('file', this.selectedFile);
    //   this.userService.uploadUserXlsx(formData).subscribe({
    //     next: (data) => {
    //       this.getUsers();
    //     },
    //   });
    // }
  }
  uploadUsersXlsx(fileInput: HTMLInputElement) {
    if (!this.selectedFile) return;

    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);
    console.log(this.selectedFile, formData.get('file'));
    console.log(formData);

    this.userService.uploadUserXlsx(formData).subscribe({
      next: (data) => {
        this.createMessage('Users uploaded', 'success');
        this.selectedFile = null;
        fileInput.value = '';
        this.getUsers();
      },
    });
  }

  onFilePreview() {
    if (!this.selectedFile) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      console.log(this.data);

      this.headData = this.data[0];
      this.data = this.data.slice(1); // remove first header record

      const ws2: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[1]];
      this.readDataSheet(ws2, 10);
    };
    reader.readAsArrayBuffer(this.selectedFile);
    this.previewFile = true;
  }

  private readDataSheet(ws: XLSX.WorkSheet, startRow: number) {
    /* save data */
    let datas = <AOA>(
      XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, range: startRow })
    );
    console.log(datas[1]);
    let headDatas = datas[0];
    datas = datas.slice(1); // remove first header record

    for (let i = 0; i < this.data.length; i++) {
      this.data[i][this.headData.length] = datas.filter(
        (x) => x[12] == this.data[i][0]
      );
    }
    console.log(this.data[1]);
  }

  showModal(): void {
    this.previewFile = true;
  }

  handleOk(): void {
    console.log('Button ok clicked!');
    this.previewFile = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.previewFile = false;
  }
  deleteSelectedFile(fileInput: HTMLInputElement) {
    this.selectedFile = null;
    fileInput.value = ''; // Reset the file input

    // this.getUsers();
  }
}
