import { Component, OnInit } from '@angular/core';
import { ApplicationsService } from '../../services/applications.service';
import { Applications } from '../../models/applications';
import { CommonModule, NgFor } from '@angular/common';
import {
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NzModalModule } from 'ng-zorro-antd/modal';

import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { ClickPropagationDirective } from '../../directives/click-propagation.directive';
import { TransformImagePipe } from '../../pipes/transform-image.pipe';
import { KeycloakAdminService } from '../../services/keycloak-admin.service';
import { NzUploadFile, NzUploadModule } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';
import { CategoriesService } from '../../services/categories.service';
import { ICategory } from '../../models/categories';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { TreeNode } from '../../models/treeNode';
import { TranslateModule } from '@ngx-translate/core';
import { LangService } from '../../services/lang.service';
import { GroupsService } from '../../services/groups.service';
import { RolesService } from '../../services/roles.service';
import { IGroup } from '../../models/group';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-applications',
  standalone: true,
  imports: [
    TransformImagePipe,
    CommonModule,
    NzTableModule,
    NzDividerModule,
    NgFor,
    NzAffixModule,
    NzButtonModule,
    NzIconModule,
    NzFlexModule,
    NzDrawerModule,
    NzFormModule,
    FormsModule,
    NzInputModule,
    NzSwitchModule,
    NzSelectModule,
    NzMessageModule,
    NzPopconfirmModule,
    ReactiveFormsModule,
    RouterLink,
    ClickPropagationDirective,
    NzUploadModule,
    NzModalModule,
    NzTreeSelectModule,
    TranslateModule,
  ],
  templateUrl: './applications.component.html',
  styleUrl: './applications.component.css',
})
export class ApplicationsComponent implements OnInit {
  groups: IGroup[] = [];
  assignRoles!: any[];
  onChange($event: any) {
    console.log($event);
  }
  applications!: Applications[];
  visible: boolean = false;
  drawerTitle: any;
  isUpdate!: boolean;
  roles: any[] = [{ name: 'user' }, { name: 'admin' }, { name: 'Non' }];
  users: any;
  loading = false;
  avatarUrl?: string;
  previewImage: any;
  previewVisible!: boolean;
  fileList: any[] = [];
  app!: Applications;
  assignedUsers!: any[];
  searchText: any;
  allApplications!: Applications[];
  statusFilters = [
    { text: 'Active', value: true },
    { text: 'Inactive', value: false },
    { text: 'All', value: 'all' },
  ];
  categories: ICategory[] = [];
  userGroups!: any[];
  assignGroup: any;
  transformedNodes: any = [];
  constructor(
    private appService: ApplicationsService,
    private message: NzMessageService,
    private fb: NonNullableFormBuilder,
    private categoryService: CategoriesService,
    private langService: LangService,
    private groupService: GroupsService,
    private roleService: RolesService,
    private userService: UsersService
  ) {}

  ngOnInit(): void {
    this.getAllGroups();
    this.getAllRoles();
    this.getApplications();
    this.getAllUsers();
    this.getAllCategories();
  }

  handlePreview = async (file: any) => {
    if (!file.url && !file.thumbUrl) {
      file.thumbUrl = await this.getBase64(file.originFileObj);
    } else if (!file.url && !file.thumbUrl && !file.originFileObj) {
      file.thumbUrl = file.imageData;
    }

    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
  };

  handleCancel(): void {
    this.previewVisible = false;
  }
  handleDownload(file: any) {
    const link = document.createElement('a');
    link.href = file.url || file.thumbUrl;
    link.download = file.name;
    link.click();
  }
  handleChange(info: any): void {
    const file = info.file;

    if (file.status === 'done' || file.status === 'uploading') {
      this.getBase64(file.originFileObj).then((base64: string) => {
        this.applicationForm.patchValue({
          imageBase64: {
            name: file.name,
            contentType: file.type,
            imageData: base64,
          },
        });
      });

      this.fileList = [{ ...file, showDownloadIcon: false }]; // Ensure only one file is kept in the list
    } else if (file.status === 'removed') {
      this.fileList = [];
      this.applicationForm.patchValue({
        imageBase64: {
          name: '',
          contentType: '',
          imageData: '',
        },
      });
    }
  }

  getAllGroups() {
    //  this.kcAdminService.getGroups().then((groups) => {
    //   this.groups = groups;
    // });
    this.groupService.getAllGroups().subscribe({
      next: (data) => {
        this.groups = data;
      },
      complete: () => {
        this.groups.map((group: any) => {
          this.transformedNodes.push(this.transformGroupToTreeNode(group));
        });
      },
    });
  }

  private getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  //application form model
  applicationForm: FormGroup<any> = this.fb.group({
    name: ['', [Validators.required]],
    description: ['', [Validators.required]],
    link: ['', [Validators.required]],
    roles: [[], [Validators.required]],
    userGroups: [[], [Validators.required]],
    isActive: [false, [Validators.required]],
    isFavourite: [false, [Validators.required]],
    imageBase64: this.fb.group({
      name: ['', [Validators.required]],
      contentType: ['', [Validators.required]],
      imageData: ['', [Validators.required]],
    }),
    categoryId: [null, Validators.required],
  });
  // // Helper function to patch FormArray values
  // patchFormArray(formArray: any, values: any[]) {
  //   if (values && values.length > 0) {
  //     const formGroupArray = values.map((item) => this.fb.group(item));
  //     formArray.patchValue(formGroupArray);
  //   }
  // }

  //getAllAplications
  getApplications() {
    this.appService.getApplications().subscribe({
      next: (data: Applications[]) => {
        this.applications = data;
        this.allApplications = data;
      },
      error: (err) => {},
    });
  }

  //get all users
  async getAllUsers() {
    // this.users = await this.kcAdminService.getUsers();
    this.userService.getUsers(20, 1).subscribe({
      next: (data) => {
        this.users = data.items;
      },
    });
  }

  //delete application
  confirm(id: number) {
    this.appService.deleteApplication(id).subscribe({
      complete: () => {
        this.getApplications();
        this.createMessage(
          this.langService.lang == 'en'
            ? 'application was delete'
            : 'Anwendung wurde gelöscht',
          'success'
        );
      },
      error(err) {},
    });
  }

  //open drawer to edit application data
  editApp(app: Applications) {
    this.visible = true;
    this.isUpdate = true;
    this.app = app;
    this.drawerTitle = 'Edit ' + app?.name + ' application';
    this.userGroups = app.userGroups.map((group) => group.id);
    this.assignRoles = app.roles.map((user: any) => user.name);

    this.fileList = [
      {
        uid: '-1',
        name: app.imageBase64.name,
        status: 'done',
        url: app.imageBase64.imageData,
        thumbUrl: app.imageBase64.imageData,
      },
    ];

    this.applicationForm.patchValue({
      name: app.name,
      description: app.description,
      link: app.link,
      isActive: app.isActive,
      isFavourite: app.isFavourite,
      categoryId: app.categoryId,
      userGroups: this.userGroups,
      imageBase64: {
        name: app.imageBase64.name,
        contentType: app.imageBase64.contentType,
        imageData: app.imageBase64.imageData,
      },
      roles: this.assignRoles,
    });
  }

  getAllCategories() {
    this.categoryService.getAllCategories().subscribe({
      next: (data) => {
        this.categories = data;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  //create message
  createMessage(message: string, type: string): void {
    this.message.create(type, `${message}`);
  }

  //open form as create new app
  createNewApp() {
    this.applicationForm.reset();
    this.isUpdate = false;
    this.visible = true;
    this.drawerTitle = 'Add new Application';
  }

  //close drawer form
  close() {
    this.fileList = [];
    this.applicationForm.patchValue({
      imageBase64: {
        name: '',
        contentType: '',
        imageData: '',
      },
    });
    this.applicationForm.reset();
    this.visible = false;
  }

  //add or edit submit
  submit() {
    if (!this.applicationForm.valid) {
      console.log(this.applicationForm.value);
      
      this.applicationForm.markAllAsTouched();
      return;
    }
    this.applicationForm.value.userGroups =
      this.applicationForm.value.userGroups.map((group: any) => ({
        id: group,
        // name: this.groups.filter((groupObj: any) => {
        //   console.log(groupObj.id == group,groupObj.id,group);

        //   return groupObj.id == group;
        // })[0]?.name,
      }));
    this.applicationForm.value.roles = this.applicationForm.value.roles.map(
      (role: any) => ({
        name: role,
        id: this.roles.filter((rolesObj: any) => {
          return rolesObj.name == role;
        })[0]?.id,
      })
    );
    console.log(this.applicationForm.value);
    if (!this.isUpdate) {
      this.appService.addApplication(this.applicationForm.value).subscribe({
        next: (data) => {
          this.getApplications();
          this.createMessage(
            this.langService.lang == 'en'
              ? `Appliction ${data.name} added succefully`
              : `Anwendung ${data.name} erfolgreich hinzugefügt`,
            'success'
          );
          this.visible = false;
          this.applicationForm.reset();
          this.fileList = [];
        },
        error: (err) => {
          this.createMessage(err, 'error');
        },
      });
    } else {
      this.appService
        .editApplication(this.app.id, this.applicationForm.value)
        .subscribe({
          next: (data) => {
            this.getApplications();
            this.createMessage(
              this.langService.lang == 'en'
                ? `Appliction ${data.name} added succefully`
                : `Anwendung ${data.name} erfolgreich hinzugefügt`,
              'success'
            );
            this.visible = false;
            this.applicationForm.reset();
            this.fileList = [];
          },
          error: (err) => {
            this.createMessage(err, 'error');
          },
        });
    }
  }
  async getAllRoles() {
    // await this.kcAdminService.getReleamRoles().then((roles) => {
    //   this.roles = roles;
    // });
    this.roleService.getAllRoles().subscribe({
      next: (data) => {
        this.roles = data;
      },
    });
  }
  searchResult() {
    console.log(this.searchText);

    if (this.searchText?.length) {
      this.applications = this.allApplications.filter((app: any) => {
        return (
          app.name?.toLowerCase().includes(this.searchText.toLowerCase()) ||
          app.link?.toLowerCase().includes(this.searchText.toLowerCase()) ||
          app.description?.toLowerCase().includes(this.searchText.toLowerCase())
        );
      });
    } else {
      this.applications = this.allApplications;
    }
  }
  clearSearch() {
    this.searchText = '';
    this.applications = this.allApplications;
  }
  //cancle delete
  cancel() {}

  highlightText(text: string, search: string): string {
    if (!search) {
      return text;
    }
    const re = new RegExp(search, 'gi');
    return text.replace(
      re,
      (match) => `<span class="highlight">${match}</span>`
    );
  }

  //filteration according to status
  filterByStatus = (status: any) => {
    if (status == null) this.applications = this.allApplications;
    else if (status != 'all') {
      this.applications = this.allApplications.filter((app: Applications) => {
        return app.isActive == status;
      });
    } else {
      this.applications = this.allApplications;
    }
    // this.loadUsers(this.getFilterParams(status));
  };
  assignAppRole(event: any) {
    console.log(event);
  }

  transformGroupToTreeNode = (group: any): TreeNode => {
    const treeNode: TreeNode = {
      title: group.name,
      key: group.id,
      children:
        group?.subGroups?.length > 0
          ? group?.subGroups?.map(this.transformGroupToTreeNode)
          : undefined,
      isLeaf: group?.subGroups?.length === 0,
    };
    return treeNode;
  };

  onSearchGroup(searchValue: any) {
    console.log(searchValue);

    if (searchValue.length > 0) {
      this.groupService.getGroupsBySearch(searchValue).subscribe({
        next: (data) => {
          this.groups = data.items;
        },
        complete: () => {
          this.groups.map((group: any) => {
            this.transformedNodes.push(this.transformGroupToTreeNode(group));
          });
        },
      });
    } else {
      this.getAllGroups();
    }
  }

  onSearchRoles(searchValue: any) {
    console.log(searchValue);
    if (searchValue?.length == 0) return;
    this.roleService.getRolesBySearch(searchValue).subscribe({
      next: (data) => {
        this.roles = data.items;
      },
    });
  }
}
