<app-loading></app-loading>

<nz-layout class="app-layout">
  <nz-sider
    *ngIf="isAuth"
    class="menu-sidebar"
    nzWidth="256px"
    nzBreakpoint="md"
    nzTheme="light"
  >  
    <div class="sidebar-logo">
      <h1>{{ "userManagement" | translate }}</h1>
      <!-- <a href="https://ng.ant.design/" target="_blank">
        <img src="https://ng.ant.design/assets/img/logo.svg" alt="logo">
        
      </a> -->
    </div>
    <ul nz-menu nzTheme="light" nzMode="inline">
      <li [routerLink]="['/users']" nz-menu-item nzMatchRouter>
        <!-- <span nz-icon nzType="team"></span> -->
        <span>{{ "sidebar.users" | translate }}</span>
      </li>
      <li [routerLink]="['/groups']" nz-menu-item nzMatchRouter>
        <!-- <span nz-icon nzType="group"></span> -->
        <span>{{ "sidebar.groups" | translate }}</span>
      </li>
      <li [routerLink]="['/roles']" nz-menu-item nzMatchRouter>
        <!-- <span nz-icon nzType="control"></span> -->
        <span> {{ "sidebar.roles" | translate }} </span>
      </li>
      <li [routerLink]="['/applications']" nz-menu-item nzMatchRouter>
        <!-- <span nz-icon nzType="control"></span> -->
        <span> {{ "sidebar.applications" | translate }} </span>
      </li>
      <li [routerLink]="['/categories']" nz-menu-item nzMatchRouter>
        <!-- <span nz-icon nzType="control"></span> -->
        <span> {{ "sidebar.categories" | translate }} </span>
      </li>
         <li [routerLink]="['/news']" nz-menu-item nzMatchRouter>
        <!-- <span nz-icon nzType="control"></span> -->
        <span> {{ "sidebar.news" | translate }} </span>
      </li>
      <!-- <li nz-menu-item nzMatchRouter>
        <img class="pe-2" src="../assets/images/logo.svg" />
        <a
          href="https://komm-portal.dev.kommunity.projects.kogo4ic.de/"
          style="text-decoration: none;font-weight: 500;"
          target="_blank"
          >Komm.Unity</a
        >
      </li> -->
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <div class="d-flex justify-content-end align-items-center gap-3 px-4">
          <a
            target="_blank"
            [href]="KommunityLink"
            class="d-flex justify-content-end align-items-center gap-2 px-4"
            style="cursor: pointer; text-decoration: none; color: white"
          >
            <span nz-icon nzType="left-circle" nzTheme="fill"></span>
            <div style="color: white" class="fw-bold">Komm.Unity</div>
          </a>
          <nz-select
            nzSize="small"
            [nzDropdownMatchSelectWidth]="false"
            [(ngModel)]="lang"
            (ngModelChange)="switchLanguage(lang)"
          >
            <nz-option nzCustomContent nzLabel="EN" nzValue="en">
              <img src="../assets/images/en.svg" width="20" height="20" />
              <!-- <span>EN</span> -->
            </nz-option>
            <nz-option nzCustomContent nzLabel="DE" nzValue="de">
              <img src="../assets/images/de.svg" width="20" height="20" />
              <!-- <span>DE</span> -->
            </nz-option>
          </nz-select>
          <!-- <button class="btn btn-sm btn-light" (click)="logout()">LogOut</button> -->
        </div>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
