import { Component, inject, OnInit } from '@angular/core';
import { INews } from '../../models/news';
import { NewsService } from '../../services/news.service';
import { TranslateModule } from '@ngx-translate/core';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import {
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { TransformImagePipe } from '../../pipes/transform-image.pipe';
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [
    TranslateModule,
    NzMessageModule,
    NzPopconfirmModule,
    ReactiveFormsModule,
    NzFormModule,
    FormsModule,
    NzInputModule,
    TransformImagePipe,
    CommonModule,
    NzTableModule,
    NzDividerModule,
    NzIconModule,
    NzButtonModule,
    NzDrawerModule,
  ],
  templateUrl: './news.component.html',
  styleUrl: './news.component.css',
})
export class NewsComponent implements OnInit {
  newsService = inject(NewsService);
  news: INews[] = [];
  allNews: INews[] = [];
  newsId!: string;
  searchvalue!: string;
  visible: boolean = false;
  fb = inject(NonNullableFormBuilder);
  isUpdate!:boolean

  ngOnInit(): void {
    this.getAllNews();
  }

  getAllNews() {
    this.newsService.getAllNews().subscribe({
      next: (value) => {
        this.news = value;
        this.allNews = value;
      },
    });
  }

  //application form model
  newsForm: FormGroup<any> = this.fb.group({
    title: ['', [Validators.required]],
    description: ['', [Validators.required]],
  });

  highlightText(text: string, search: string): string {
    if (!search) {
      return text;
    }
    const re = new RegExp(search, 'gi');
    return text.replace(
      re,
      (match) => `<span class="highlight">${match}</span>`
    );
  }
  editNews(news: any) {
    this.visible = true;
    this.isUpdate = true;
    this.newsId = news.id;
    this.newsForm.patchValue({
      title: news.title,
      description: news.description,
    });
  }

  confirm(id: string) {
    this.newsService.deleteNews(id).subscribe({
      next: (value) => {
        this.getAllNews();
      },
    });
  }

  cancel() {}
  createNews() {}
  submit(){
    if(this.isUpdate==true){
      this.newsService.editNews(this.newsId, this.newsForm.value).subscribe({
        next: (value) => {
          this.visible =false
          this.getAllNews();
        },
      })
    }
  }

  //search
  searchResult() {
    if (this.searchvalue?.length) {
      this.news = this.allNews.filter((app: any) => {
        return app.title
          ?.toLowerCase()
          .includes(this.searchvalue.toLowerCase());
      });
    } else {
      this.news = this.allNews;
    }
  }
  clearSearch() {
    this.searchvalue = '';
    this.news = this.allNews;
  }

  close() {
    // this.fileList = [];
    // this.newGroupForm.patchValue({
    //   imageBase64: {
    //     name: '',
    //     contentType: '',
    //     imageData: '',
    //   },
    // });
    // this.newGroupForm.reset();
    this.visible = false;
  }
}
