import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { INews } from '../models/news';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private httpClient: HttpClient) {}

  getAllNews() {
    return this.httpClient.get<INews[]>(environment.newsApi + '/news');
  }

  getNewsById(id: string) {
    return this.httpClient.get<INews>(environment.newsApi + '/news/' + id);
  }

  createNews(news: any) {
    this.httpClient.post(environment.newsApi + '/news', news);
  }

  editNews(id: string, news: any) {
    return this.httpClient.put<any[]>(
      environment.newsApi + '/news/' + id,
      news
    );
  }

  deleteNews(id: string) {
    return this.httpClient.delete<any[]>(environment.newsApi + '/news/' + id);
  }
}
