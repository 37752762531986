<section *ngIf="group">
  <div class="my-3 px-2 d-flex justify-content-between align-items-center">
    <h3>{{ "groups.usersMange.mange" | translate }} {{ group?.name }} group</h3>
    <div>
      <ng-template #suffixIconButton>
        <button nz-button nzType="primary" nzSearch>
          <span nz-icon nzType="search"></span>
        </button>
      </ng-template>

      <nz-input-group
        nzSearch
        [nzAddOnAfter]="suffixIconButton"
        [nzSuffix]="suffixIcon"
      >
        <input
          type="text"
          nz-input
          [placeholder]="'users.search' | translate"
          #searchValue
          [(ngModel)]="searchText"
          (input)="searchResult()"
        />
      </nz-input-group>
      <ng-template #suffixIcon>
        <i
          nz-icon
          nzType="close-circle"
          *ngIf="searchValue.value"
          (click)="clearSearch()"
        ></i>
      </ng-template>
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-center pb-4 px-2">
    <div class="w-100">
      <form nz-form (ngSubmit)="addUserToGroup()" style="width: 100%">
        <div class="d-flex gap-3 justify-content-start pt-2 px-3">
          <label for="userSelect">
            {{ "groups.usersMange.addUser" | translate }}
          </label>
          <nz-select
            [nzMaxTagCount]="5"
            nzMode="multiple"
            id="userSelect"
            nzPlaceHolder="Please select"
            [(ngModel)]="listOfSelectedUser"
            [ngModelOptions]="{ standalone: true }"
            [nzMaxTagPlaceholder]="tagPlaceHolder"
            nzShowSearch
            nzAllowClear
            nzServerSearch
            (nzOnSearch)="getUserByUserName($event)"
          >
            <nz-option
              *ngFor="let item of usersList"
              [nzLabel]="item?.username"
              [nzValue]="item?.id"
            ></nz-option>
            <ng-template #tagPlaceHolder let-selectedList
              >and {{ selectedList.length }} more selected</ng-template
            >
          </nz-select>
          <!-- <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" id="searchUsername">
            <input type="text" nz-input placeholder="input search text" [(ngModel)]="searchUsername"/>
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button (click)="getUserByUserName(searchUsername)" nz-button nzType="primary" nzSearch>
              <span nz-icon nzType="search"></span>
            </button>
          </ng-template> -->
          <button
            type="button"
            nz-button
            nzType="primary"
            (click)="addUserToGroup()"
          >
            {{ "groups.usersMange.add" | translate }}
          </button>
        </div>
      </form>
    </div>
    <div>
      <button
        (click)="downloadResponse()"
        nz-button
        nzType="default"
        [disabled]="exportData?.length == 0"
      >
        <span nz-icon nzType="export" nzTheme="outline"></span>
        {{ "groups.usersMange.exportUsers" | translate }}
      </button>
    </div>
  </div>

  <nz-table #headerTable [nzData]="groupUsers" [nzPageSize]="50">
    <thead>
      <tr>
        <th>{{ "groups.usersMange.userName" | translate }}</th>
        <th>{{ "groups.usersMange.usersRoles" | translate }}</th>
        <th>{{ "groups.usersMange.Delete" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of headerTable.data">
        <td>
          <span [innerHTML]="highlightText(data.username, searchText)"></span>
        </td>
        <td>
          <span *ngFor="let role of data?.roles">
            {{ role.name }}
          </span>
        </td>
        <td>
          <button
            nz-button
            nz-popconfirm
            [nzPopconfirmTitle]="'roles.confirmDelete' | translate"
            [nzOkText]="'roles.yes' | translate"
            [nzCancelText]="'roles.cancel' | translate"
            (nzOnConfirm)="deleteUser(data?.id)"
            nzType="default"
            nzShape="circle"
          >
            <span nz-icon nzType="delete"></span>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</section>
