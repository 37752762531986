import { Component, Input, OnInit } from '@angular/core';
import { KeycloakAdminService } from '../../../services/keycloak-admin.service';
import RoleRepresentation from '@keycloak/keycloak-admin-client/lib/defs/roleRepresentation';
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { FormGroup, FormsModule, NonNullableFormBuilder } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { LoadingService } from '../../../services/loading.service';
import { UsersService } from '../../../services/users.service';
import { TranslateModule } from '@ngx-translate/core';
import { GroupsService } from '../../../services/groups.service';

@Component({
  selector: 'app-edit-users-group',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    NzTableModule,
    NzIconModule,
    NzPopconfirmModule,
    NzSelectModule,
    FormsModule,
    NzButtonModule,
    NzInputModule,
  ],
  templateUrl: './edit-users-group.component.html',
  styleUrl: './edit-users-group.component.css',
})
export class EditUsersGroupComponent implements OnInit {
  @Input() id: any;
  groupUsers: any[] = [];
  orGroupUsers: any[] = [];
  usersList: any[] = [];
  roles: RoleRepresentation[] | undefined;
  allUsers: any;
  group: any;
  listOfSelectedUser: any;
  searchText: string = '';
  exportData: any;
  searchUsername!: string;
  constructor(
    private fb: NonNullableFormBuilder,
    private userService: UsersService,
    private groupService: GroupsService
  ) {}
  async ngOnInit() {
    this.getUsers();
    if (this.id) {
      this.getExportedData(this.id);
      this.getGroupDetails(this.id);
    }
  }
  getExportedData(id: any) {
    this.userService.exportUsers(id).subscribe({
      next: (data: any) => {
        this.exportData = data;
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }
  //create form
  addUserForm: FormGroup = this.fb.group({});

  //get groupData by id
  getGroupDetails(id: any) {
    this.groupService.getGroupById(id).subscribe({
      next: (data: any) => {
        this.group = data;
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }

  //load users according to group id then load list users
  loadUsersAndRoles(groupId: string) {
    this.groupService.getGroupMembers(groupId).subscribe({
      next: (data) => {
        this.groupUsers = data;
        this.orGroupUsers = data;
        let threeUserIds = new Set(this.groupUsers?.map((user) => user.id));
        this.usersList = this.allUsers?.filter(
          (user: any) => !threeUserIds.has(user.id)
        );
      },
      complete: () => {
        this.groupUsers?.forEach((user) => {
          this.userService.getUserRolesById(user.id).subscribe({
            next: (data) => {
              user.roles = data;
              console.log(data,user);
            },
          });
        });
      },
    });
  }

  //load all users
  getUsers() {
    this.userService.getUsers(20, 1).subscribe({
      next: (data) => {
        console.log(data.items);

        this.allUsers = data.items;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        this.loadUsersAndRoles(this.id);
      },
    });
  }

  //delete selected user from group
  async deleteUser(userId: string) {
    if (this.id) {
      this.userService.deleteUserFromGroup(userId, this.id).subscribe({
        next: (data) => {
          this.loadUsersAndRoles(this.id);
        },
      })
    }
  }

  //add new user for the group
  addUserToGroup() {
    this.groupService
      .addMembersToGroup(this.id, this.listOfSelectedUser)
      .subscribe({
        next: (data) => {
          this.loadUsersAndRoles(this.id);
        },
      });
  }

  //filterations according to search result
  searchResult() {
    if (this.searchText?.length) {
      this.groupUsers = this.orGroupUsers.filter((app: any) => {
        return app.username
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase());
      });
    } else {
      this.groupUsers = this.orGroupUsers;
    }
  }
  clearSearch() {
    this.searchText = '';
    this.groupUsers = this.orGroupUsers;
  }
  highlightText(text: string, search: string): string {
    if (!search) {
      return text;
    }
    const re = new RegExp(search, 'gi');
    return text.replace(
      re,
      (match) => `<span class="highlight">${match}</span>`
    );
  }

  downloadResponse() {
    const jsonData = JSON.stringify(this.exportData, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${this.group?.name}_users.json`;
    a.click();
    URL.revokeObjectURL(url);
  }
  //get user by user name as search value
  getUserByUserName(username: string) {
    if (!username) return;
    this.userService.getUserBySearch(username).subscribe({
      next: (data) => {
        this.usersList = data.items;
      },
    })
  }
  // downloadExcel() {
  //   // Create a worksheet from the JSON data
  //   const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportData);
  //   // Create a new workbook
  //   const workbook: XLSX.WorkBook = {
  //     Sheets: { data: worksheet },
  //     SheetNames: ['data'],
  //   };
  //   // Save to file
  //   XLSX.writeFile(workbook, 'response.xlsx');
  // }
}
