import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, RouterOutlet } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { LoadingComponent } from './components/loading/loading.component';
import { environment } from '../environments/environment';
import {
  TranslateModule,
  TranslatePipe,
  TranslateService,
} from '@ngx-translate/core';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule } from '@angular/forms';
import { LangService } from './services/lang.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    NzIconModule,
    NzLayoutModule,
    NzMenuModule,
    RouterModule,
    LoadingComponent,
    TranslateModule,
    NzSelectModule,
    FormsModule,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  isCollapsed = false;
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly langService = inject(LangService);
  translate = inject(TranslateService);
  KommunityLink = environment.kommUnity;
  lang!: any;
  isAuth: boolean=false;
  ngOnInit(): void {
    this.lang = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : 'de';
    this.translate.setDefaultLang(this.lang);
    this.langService.changeLanguage(this.lang);
    // console.log("on init");
    //this.oidcSecurityService.authorize();
    this.oidcSecurityService
      .checkAuth()
      .subscribe((loginResponse: LoginResponse) => {
        const { isAuthenticated, userData, accessToken, idToken, configId } =
          loginResponse;
         this.isAuth = loginResponse.isAuthenticated
          
        // console.log('app authenticated', isAuthenticated);
        // console.log(`Current access token is '${accessToken}'`);
      });
    /* this.oidcSecurityService.authorize(); */
  }

  login(): void {
    console.log('start login');
    this.oidcSecurityService.authorize();
  }

  refreshSession(): void {
    console.log('start refreshSession');
    this.oidcSecurityService.authorize();
  }

  logout(): void {
    console.log('start logoff');
    this.oidcSecurityService
      .logoff()
      .subscribe((result) => console.log(result));
  }
  switchLanguage(lang: any) {
    this.langService.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    this.translate.use(lang);
  }
}
