import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsService {
  constructor(private http: HttpClient) {}

  // Fetch All applications
  getApplications() {
    return this.http.get<any>(environment.apiUrl + '/applications');
  }

  // Fetch applications with admin scope
  getApplicationsForAdmin() {
    return this.http.get<any>(
      environment.apiUrl + '/applications/filter?assignedRole=admin'
    );
  }

  // Fetch applications with user scope
  getApplicationsForUser() {
    return this.http.get<any>(
      environment.apiUrl + '/applications/filter?assignedRole=user'
    );
  }

  //fetch projects for user
  getapplicationsByUserName(userName: string) {
    return this.http.get<any>(
      environment.apiUrl + `/applications/filter?assignedUser=${userName}`
    );
  }
  //Add project
  addApplication(app: any) {
    return this.http.post<any>(environment.apiUrl + `/applications`, {
      ...app,
    });
  }

  //edit project data
  editApplication(appId:any,app: any) {
    return this.http.patch<any>(environment.apiUrl + `/applications/${appId}`, {
      ...app,
    });
  }
  //delete application
  deleteApplication(id: any) {
    return this.http.delete<any>(environment.apiUrl + `/applications/${id}`);
  }
}
