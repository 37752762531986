import { Component, Input, OnInit } from '@angular/core';
import { KeycloakAdminService } from '../../../services/keycloak-admin.service';
import { NzTransferModule, TransferItem } from 'ng-zorro-antd/transfer';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { CommonModule } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { UsersService } from '../../../services/users.service';
import { User } from '../../../models/user';
import { RolesService } from '../../../services/roles.service';
import { IRole } from '../../../models/roles';
import { GroupsService } from '../../../services/groups.service';
import { TranslateModule } from '@ngx-translate/core';
import { LangService } from '../../../services/lang.service';
import { IGroup } from '../../../models/group';

@Component({
  selector: 'app-edit-user',
  standalone: true,
  imports: [
    NzTransferModule,
    NzTableModule,
    NzSwitchModule,
    CommonModule,
    FormsModule,
    NzInputModule,
    NzDrawerModule,
    ReactiveFormsModule,
    NzFormModule,
    NzButtonModule,
    TranslateModule,
  ],
  templateUrl: './edit-user.component.html',
  styleUrl: './edit-user.component.css',
})
export class EditUserComponent implements OnInit {
  @Input() id: any;
  userData!: User;
  userRoles!: IRole[];
  realmRoles!: IRole[];
  disabled = false;
  visibleEdit: boolean = false;
  showSearch = false;
  combinedRoles: any;
  targetKeys: any;
  groups: any = [];
  allgroupData: IGroup[] = [];
  userGroups: any;
  combinedGroups: any;
  targetKeysGroups: any;
  disapled: boolean = false;
  groupPageNumber: number = 1;
  groupPageSize: number = 10;
  rolePageSize: number = 10;
  rolePageNumber: number = 1;
  roleTotalItems: number = 0;
  groupTotalItems: number = 0;
  constructor(
    private message: NzMessageService,
    private fb: NonNullableFormBuilder,
    private langService: LangService,
    private userService: UsersService,
    private roleService: RolesService,
    private groupService: GroupsService
  ) {}
  async ngOnInit() {
    this.getRealmRoles();
    this.loadGroups();
    if (this.id) {
      this.getUserData(this.id);
    }
  }

  //implement change password form
  changePasswordForm: FormGroup = this.fb.group({
    password: ['', [Validators.required]],
    confirmationPassword: ['', [Validators.required]],
  });

  //handel trasnfare data and directions for rolles
  handelTrasfareDirections() {
    const userRoleIds = new Set(this.userRoles.map((role: any) => role.id));

    const filteredRealmRoles = this.realmRoles.filter(
      (role: any) => !userRoleIds.has(role.id)
    );

    // Combine userRoles and filteredRealmRoles
    this.combinedRoles = [
      ...this.userRoles.map((role: any) => ({
        id: role.id,
        name: role.name,
        direction: 'right',
      })),
      ...filteredRealmRoles.map((role: any) => ({
        id: role.id,
        name: role.name,
        direction: 'left',
      })),
    ];

    this.targetKeys = this.combinedRoles
      .filter((role: any) => role.direction === 'right')
      .map((role: any) => role.key);
  }

  //handel trasnfare data and directions for groups
  handelTrasfareDirectionsForGroups() {
    const userGroupId = new Set(this.userGroups.map((role: any) => role.id));

    const filteredRealmRoles = this.groups.filter(
      (role: any) => !userGroupId.has(role.id)
    );

    // Combine userRoles and filteredRealmRoles
    this.combinedGroups = [
      ...this.userGroups.map((group: any) => ({
        id: group.id,
        name: group.name,
        direction: 'right',
      })),
      ...filteredRealmRoles.map((group: any) => ({
        id: group.id,
        name: group.name,
        direction: 'left',
      })),
    ];

    this.targetKeysGroups = this.combinedGroups
      .filter((group: any) => group.direction === 'right')
      .map((group: any) => group.key);
  }

  //get user data by user id
  getUserData(id: string) {
    this.userService.getUserById(id).subscribe({
      next: (data) => {
        this.userData = data;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  //get user role
  getUserRoles(id: string) {
    this.userService.getUserRolesById(id).subscribe({
      next: (data) => {
        this.userRoles = data;
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
        this.handelTrasfareDirections();
      },
    });
  }

  //get all realm role
  async getRealmRoles() {
    this.roleService
      .getAllRoles()
      .subscribe({
        next: (data) => {
          this.realmRoles = data;
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
          this.getUserRoles(this.id);
        },
      });
  }

  //on transfare data
  onChange(event: any): void {
    if (event?.list?.length == 0) return;
    if (event.from == 'right') {
      //delete role
      const newArray: any[] = [];
      event?.list.forEach((data: any) => {
        newArray.push({
          name: data.name,
          id: data.id,
        });
      });
      newArray.forEach((data: any) => {
        this.userService.deleteRoleFromUser(this.userData.id, data).subscribe({
          next: (data) => {
            this.createMessage(
              this.langService.lang == 'en'
                ? 'The role has been successfully removed'
                : 'Die Rolle wurde erfolgreich entfernt',
              'success'
            );
          },
          error: (err) => {
            console.log(err);
          },
        });
      });
    } else {
      //assign role to user
      const newArray: any[] = [];
      event?.list.forEach((data: any) => {
        newArray.push({
          name: data.name,
        });
      });

      this.userService.assignRolesToUser(this.userData.id, newArray).subscribe({
        next: (data) => {
          this.createMessage(
            this.langService.lang == 'en'
              ? 'The role was added successfully'
              : 'Die Rolle wurde erfolgreich hinzugefügt',
            'success'
          );
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
  }

  // get all groups
  loadGroups() {
    this.groupService
      .getAllGroups()
      .subscribe({
        next: (data) => {
          this.allgroupData = data;
          this.customizeGroups(data);
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
          this.getGroupsOfUser(this.id);
        },
      });
  }
  //customize all group to be in the same level
  customizeGroups(groups: any[]) {
    groups.forEach((group) => {
      this.groups.push(group);
      if (group?.subGroups?.length > 0) this.customizeGroups(group.subGroups);
    });
  }

  //get user's groups
  getGroupsOfUser(userId: string) {
    this.userService.getUserGroupById(userId).subscribe({
      next: (data) => {
        this.userGroups = data;
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
        this.handelTrasfareDirectionsForGroups();
      },
    });
  }

  //on transfare data
  onChangeGroup(event: any): void {
    if (event?.list?.length == 0) return;
    this.disabled = true;
    if (event.from == 'right') {
      //delete role
      const newArray: any[] = [];
      event?.list.forEach((data: any) => {
        newArray.push({
          id: data.id,
        });
      });
      this.userService
        .deleteUserFromGroup(this.userData.id, newArray[0]?.id)
        .subscribe({
          next: (data) => {
            this.createMessage(
              this.langService.lang == 'en'
                ? 'The user was successfully removed from the group'
                : 'Der Benutzer wurde erfolgreich aus der Gruppe entfernt',
              'success'
            );
          },
          error: (err) => {
            console.log(err);
          },
        });
    } else {
      //add role
      const newArray: any[] = [];
      event?.list.forEach((data: any) => {
        newArray.push({
          id: data.id,
        });
      });
      this.userService
        .addGroupToUser(this.userData.id, newArray[0]?.id)
        .subscribe({
          next: (data) => {
            this.createMessage(
              this.langService.lang == 'en'
                ? 'The user was successfully added to the group'
                : 'Der Benutzer wurde erfolgreich zur Gruppe hinzugefügt',
              'success'
            );
            this.combinedGroups.forEach((data: any, index: number) => {
              this.combinedGroups[index].disapled = false;
              // this.combinedGroups[index].checked = true;
            });
          },
        });
    }
  }
  //create message
  createMessage(message: string, type: string): void {
    this.message.create(type, `${message}`);
  }

  onItemSelect(data: any) {
    const newArray: any[] = [];
    data?.list.forEach((data: any) => {
      newArray.push({
        id: data.id,
      });
    });
    this.combinedGroups.forEach((data: any, index: number) => {
      if (data?.id != newArray[0]?.id) {
        this.combinedGroups[index].disapled = true;
        this.combinedGroups[index].checked = false;
      }
    });
  }
  close() {
    this.visibleEdit = false;
  }
  async changeUserPassword() {
    if (this.changePasswordForm.value.password.length >= 8) {
      this.userService
        .updateUserPassword(this.id, this.changePasswordForm.value.password)
        .subscribe({
          next: (data) => {
            this.createMessage(
              this.langService.lang == 'en'
                ? 'The password was changed successfully'
                : 'Das Passwort wurde erfolgreich geändert',
              'success'
            );
            this.close();
            this.changePasswordForm.reset();
          },
          error: (err) => {
            // console.log(err);
            this.close();
          },
        });
    }
  }

  onPageRoleChange(page: number): void {
    this.rolePageNumber = page;
    this.getRealmRoles()
  }
}
