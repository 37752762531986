<section class="p-3">
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex gap-3">
      <button
        nz-button
        nzSize="large"
        nzType="default"
        class="addButton"
        (click)="createNewUser()"
      >
        {{ "users.addUser" | translate }}
        <span nz-icon nzType="user-add"></span>
      </button>
      <div class="d-flex gap-3">
        <input
          type="file"
          id="usersFile"
          (change)="onFileSelected($event)"
          #fileInput
          hidden
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <label for="usersFile" class="d-flex" *ngIf="!selectedFile">
          <div
            class="uploadButton d-flex gap-3 align-items-center"
            *ngIf="!selectedFile"
          >
            {{ "users.uploadUsersFile" | translate }}

            <span nz-icon nzType="cloud-upload" nzTheme="outline"></span>
          </div>
        </label>
        <div
          *ngIf="selectedFile"
          class="d-flex gap-4 align-items-center fileSelectedContainer"
        >
          <div class="d-flex gap-1 align-items-center">
            <span nz-typography>{{ selectedFile.name }}</span>
            <button
              nz-button
              class="m-0"
              nzType="link"
              (click)="onFilePreview()"
            >
              <span nz-icon nzType="file-search"></span>
            </button>
            <button
              nz-button
              class="m-0 text-danger"
              nzType="link"
              (click)="deleteSelectedFile(fileInput)"
            >
              <span nz-icon nzType="delete"></span>
            </button>
          </div>
          <button
            nz-button
            nzSize="large"
            nzType="dashed"
            class="addButton2"
            (click)="uploadUsersXlsx(fileInput)"
            *ngIf="selectedFile"
          >
            {{ "users.uploadUsers" | translate }}
            <span nz-icon nzType="usergroup-add" nzTheme="outline"></span>
          </button>
        </div>
        <nz-modal
          [(nzVisible)]="previewFile"
          [nzTitle]="selectedFile?.name"
          (nzOnCancel)="handleCancel()"
          [nzOkText]="null"
          [nzCancelText]="'users.cancel' | translate"
          [nzClosable]="true"
        >
          <ng-container *nzModalContent>
            <nz-table #basicTable [nzData]="data" nzShowPagination="false">
              <thead>
                <tr>
                  <th *ngFor="let headValue of headData">{{ headValue }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of data">
                  <td *ngFor="let val of headData; let i = index">
                    {{ row[i] || " " }}
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </ng-container>
        </nz-modal>
      </div>
    </div>
    <div>
      <ng-template #suffixIconButton>
        <button nz-button nzType="primary" nzSearch>
          <span nz-icon nzType="search"></span>
        </button>
      </ng-template>

      <nz-input-group
        nzSearch
        [nzAddOnAfter]="suffixIconButton"
        [nzSuffix]="suffixIcon"
      >
        <input
          type="text"
          nz-input
          [placeholder]="'users.search' | translate"
          #searchValue
          [(ngModel)]="searchText"
          (input)="searchResult()"
        />
      </nz-input-group>
      <ng-template #suffixIcon>
        <i
          nz-icon
          nzType="close-circle"
          *ngIf="searchValue.value"
          (click)="clearSearch()"
        ></i>
      </ng-template>
      <!-- <ng-template #suffixclear>
        <span (click)="this.getAllUsers()">
          <span nz-icon nzType="close" nzTheme="outline"></span>
        </span>
      </ng-template> -->
    </div>
  </div>

  <!-- <div nz-row> -->
  <nz-table
    #basicTable
    [nzData]="users"
    [nzFrontPagination]="false"
    [nzTotal]="total"
    [nzPageSize]="pageSize"
    [nzPageIndex]="pageNumber"
    (nzPageIndexChange)="onPageIndexChange($event)"
    (nzPageSizeChange)="onPageSizeChange($event)"
    [nzScroll]="{ x: 'auto' }"
  >
    <thead>
      <tr>
        <th nzLeft>{{ "users.userName" | translate }}</th>
        <th>{{ "users.firstName" | translate }}</th>
        <th>{{ "users.lastName" | translate }}</th>
        <th>{{ "users.email" | translate }}</th>
        <th
          [nzFilters]="statusFilters"
          [nzFilterMultiple]="false"
          (nzFilterChange)="filterByStatus($event)"
        >
          {{ "users.status" | translate }}
        </th>
        <th nzRight>{{ "users.actions" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data" style="cursor: pointer">
        <td
          nzLeft
          style="cursor: pointer"
          class="text-51b2a8"
          [routerLink]="['/users/' + data?.id]"
        >
          <span [innerHTML]="highlightText(data.username, searchText)"></span>
        </td>
        <td>
          <span [innerHTML]="highlightText(data.firstName, searchText)"></span>
        </td>
        <td>
          <span [innerHTML]="highlightText(data.lastName, searchText)"></span>
        </td>
        <td>
          <span [innerHTML]="highlightText(data.email, searchText)"></span>
        </td>
        <td>
          <span
            [class.bg-active]="data.enabled"
            [class.bg-notactive]="!data.enabled"
            >{{ data.enabled ? "Active" : "Inactive" }}</span
          >
        </td>
        <td nzRight>
          <a
            appClickPropagation
            nz-icon
            nzType="edit"
            nzTheme="outline"
            (click)="editUser(data)"
            class="text-primary"
          ></a>
          <nz-divider nzType="vertical"></nz-divider>
          <a
            appClickPropagation
            nz-icon
            nzType="delete"
            nzTheme="outline"
            nz-popconfirm
            [nzPopconfirmTitle]="'roles.confirmDelete' | translate"
            [nzOkText]="'roles.yes' | translate"
            [nzCancelText]="'roles.cancel' | translate"
            (nzOnConfirm)="confirm(data.id)"
            (nzOnCancel)="cancel()"
            class="text-danger"
          ></a>
        </td>
      </tr>
    </tbody>
  </nz-table>
</section>

<!-- </div> -->
<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]="false"
  [nzWidth]="500"
  [nzVisible]="visible"
  nzTitle="{{ drawerTitle }}"
  [nzFooter]="footerTpl"
  (nzOnClose)="close()"
>
  <form
    *nzDrawerContent
    nz-form
    [formGroup]="newUserForm"
    (ngSubmit)="createUser()"
  >
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="enabled">
        {{ "users.enabled" | translate }}
      </nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-switch formControlName="enabled" name="enabled"></nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="otp">
        {{ "users.otp" | translate }}
      </nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-switch formControlName="otp" name="otp"></nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="email">
        {{ "users.email" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="
          newUserForm.get('email')?.invalid &&
          (newUserForm.get('email')?.touched || newUserForm.get('email')?.dirty)
            ? ('users.emailError' | translate)
            : null
        "
      >
        <input nz-input formControlName="email" id="email" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="username">
        {{ "users.userName" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="
          newUserForm.get('username')?.invalid &&
          (newUserForm.get('username')?.touched ||
            newUserForm.get('username')?.dirty)
            ? ('users.usernameError' | translate)
            : null
        "
      >
        <input nz-input formControlName="username" id="username" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="!isUpdate">
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="password">
        {{ "users.password" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="
          newUserForm.get('password')?.invalid &&
          (newUserForm.get('password')?.touched ||
            newUserForm.get('password')?.dirty)
            ? ('users.passwordError' | translate)
            : null
        "
      >
        <input
          nz-input
          formControlName="password"
          id="password"
          type="password"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="firstName">
        {{ "users.firstName" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="
        newUserForm.get('firstName')?.invalid &&
        (newUserForm.get('firstName')?.touched || newUserForm.get('firstName')?.dirty)
          ? ('users.firstNameError' | translate)
          : null
      "
      >
        <input nz-input formControlName="firstName" id="firstName" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="lastName">
        {{ "users.lastName" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="
        newUserForm.get('lastName')?.invalid &&
        (newUserForm.get('lastName')?.touched || newUserForm.get('lastName')?.dirty)
          ? ('users.lastNameError' | translate)
          : null
      "
      >
        <input nz-input formControlName="lastName" id="lastName" />
      </nz-form-control>
    </nz-form-item>
  </form>

  <ng-template #footerTpl>
    <div style="float: right">
      <button nz-button style="margin-right: 8px" (click)="close()">
        {{ "users.cancel" | translate }}
      </button>
      <button nz-button nzType="primary" (click)="createUser()">
        {{ "users.submit" | translate }}
      </button>
    </div>
  </ng-template>
</nz-drawer>
