<section class="p-3">
  <div class="d-flex justify-content-between mb-3">
    <div>
      <button
        nz-button
        nzSize="large"
        nzType="default"
        (click)="createCategory()"
        class="align-items-center d-flex addButton h-100"
      >
        {{ "groups.addGroup" | translate }}
        <span nz-icon nzType="group" nzTheme="outline"></span>
      </button>
    </div>
    <div>
      <ng-template #suffixIconButton>
        <button nz-button nzType="primary" nzSearch>
          <span nz-icon nzType="search"></span>
        </button>
      </ng-template>
      <nz-input-group
        nzSearch
        [nzAddOnAfter]="suffixIconButton"
        [nzSuffix]="suffixIcon"
      >
        <input
          type="text"
          nz-input
          [placeholder]="'groups.search' | translate"
          [(ngModel)]="searchValue"
          (ngModelChange)="filterNodes()"
        />
      </nz-input-group>
      <ng-template #suffixIcon>
        <i
          nz-icon
          nzType="close-circle"
          *ngIf="searchValue?.length"
          (click)="clearSearch()"
        ></i>
      </ng-template>
    </div>
  </div>
  <nz-table
    #expandTable
    [nzData]="filteredNodes"
    nzTableLayout="fixed"
    [nzFrontPagination]="false"
    [nzScroll]="{ x: 'auto' }"
    [nzTotal]="totalCount"
    [nzPageSize]="pageSize"
    [nzPageIndex]="pageNumber"
    (nzPageIndexChange)="onPageIndexChange($event)"
    (nzPageSizeChange)="onPageSizeChange($event)"
  >
    <thead>
      <tr>
        <th>{{ "groups.name" | translate }}</th>
        <th nzWidth="300px">{{ "groups.id" | translate }}</th>
        <th>{{ "groups.users" | translate }}</th>
        <th>{{ "groups.roles" | translate }}</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let data of expandTable.data">
        <ng-container *ngFor="let item of mapOfExpandedData[data.key]">
          <tr *ngIf="(item.parent && item?.parent?.expand) || !item.parent">
            <td
              [nzIndentSize]="item.level! * 20"
              [nzShowExpand]="!!item?.children?.length"
              [(nzExpand)]="item.expand"
              (nzExpandChange)="
                collapse(mapOfExpandedData[data.key], item, $event)
              "
            >
              <span [innerHTML]="highlightText(item.name, searchValue)"></span>
            </td>
            <td class="text-51b2a8">{{ item.key }}</td>
            <td>
              <button
                [routerLink]="['/groups/users/' + item?.key]"
                class="btn text-primary px-0"
              >
                {{ "groups.mange" | translate }}
                <span nz-icon nzType="select" nzTheme="outline"></span>
              </button>
            </td>
            <td>
              <button
                [routerLink]="['/groups/' + item?.key]"
                nz-button
                nzSize="large"
                nzType="link"
              >
                {{ "groups.mange" | translate }}
                <span nz-icon nzType="select" nzTheme="outline"></span>
              </button>
            </td>

            <td>
              <div class="d-flex gap-3">
                <button
                  nz-button
                  nz-popconfirm
                  [nzPopconfirmTitle]="'roles.confirmDelete' | translate"
                  [nzOkText]="'roles.yes' | translate"
                  [nzCancelText]="'roles.cancel' | translate"
                  (nzOnConfirm)="confirm(item?.key)"
                  (nzOnCancel)="cancel()"
                  nzType="default"
                  nzShape="circle"
                  class="text-danger border-danger"
                >
                  <span nz-icon nzType="delete"></span>
                </button>
                <button
                  nz-button
                  nzType="default"
                  nzShape="circle"
                  (click)="setEditableGroup(item)"
                >
                  <a nz-icon nzType="edit" nzTheme="outline"></a>
                </button>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </nz-table>
  <!-- <div nz-row [nzGutter]="16">
    <div nz-col nzSpan="8">
      <nz-tree
        [nzData]="filteredNodes"
        nzShowIcon
        [nzExpandAll]="true"
        [nzTreeTemplate]="nzTreeTemplate"
        [nzSearchValue]="searchValue"
        (nzClick)="selectNode($event)"
      >
      </nz-tree>
      <ng-template #nzTreeTemplate let-node>
        <span [innerHTML]="highlightText(node.title, searchValue)"></span>
      </ng-template>
    </div>
    <div nz-col nzSpan="16">
      <nz-card *ngIf="selectedNode" nzTitle="Group Details">
        <p><strong>ID:</strong> {{ selectedNode?.key }}</p>
        <p><strong>Name:</strong> {{ selectedNode?.title }}</p>
        <div class="d-flex justify-content-center gap-2 align-items-center">
          <button
            nz-button
            nz-popconfirm
            nzPopconfirmTitle="Are you sure?"
            nzOkText="ok"
            nzCancelText="cancel"
            (nzOnConfirm)="confirm(selectedNode?.key)"
            (nzOnCancel)="cancel()"
            nzType="default"
            nzShape="circle"
          >
            <span nz-icon nzType="delete"></span>
          </button>
          <button
            nz-button
            nzType="default"
            nzShape="circle"
            (click)="setEditableGroup(selectedNode)"
          >
            <a nz-icon nzType="edit" nzTheme="outline"></a>
          </button>
        </div>
      </nz-card>

      <nz-card *ngIf="selectedNode" style="margin-top: 20px">
        <div nz-row nzAlign="middle" nzJustify="space-between">
          <div class="d-flex justify-content-between w-100">
            <h3>Users in Group</h3>
            <button (click)="visibleAddUser = true" class="btn text-primary">
              Benutzer hinzufügen
            </button>
          </div>
        </div>
        <nz-list
          *ngIf="users.length"
          [nzDataSource]="users"
          nzBordered
          nzSize="small"
        >
          <nz-list-item *ngFor="let user of users">
            <nz-list-item-meta
              [nzTitle]="user.username"
              [nzDescription]="user.email"
            >
            </nz-list-item-meta>
            <div>
              <span
                *ngFor="let role of user?.roles?.realmMappings"
                style="margin-left: 3px"
                >{{ role.name }}</span
              >
              <button
                nz-button
                nzType="default"
                nzShape="circle"
                (click)="deleteUser(user.id)"
              >
                <span nz-icon nzType="delete"></span>
              </button>
            </div>
          </nz-list-item>
        </nz-list>
      </nz-card>
      <nz-card
        *ngIf="roles?.length"
        nzTitle="Roles in Group"
        style="margin-top: 20px"
      >
        <nz-list [nzDataSource]="roles" nzBordered nzSize="small">
          <nz-list-item *ngFor="let role of roles">
            <nz-list-item-meta
              [nzTitle]="role.name"
              [nzDescription]="role.description"
            >
            </nz-list-item-meta>
          </nz-list-item>
        </nz-list>
      </nz-card>
      <div *ngIf="selectedNode" class="d-flex my-3 justify-content-center">
        <button
          [routerLink]="['/groups/' + selectedNode?.key]"
          nz-button
          nzSize="large"
          nzType="link"
        >
          Mange Group's roles
        </button>
      </div>
    </div>
  </div> -->
</section>

<!-- =================================drawer to add new Group OR new subGroup ================================== -->
<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]="false"
  [nzWidth]="500"
  [nzVisible]="visible"
  nzTitle="Add new Group"
  [nzFooter]="footerTpl"
  (nzOnClose)="close()"
>
  <form
    *nzDrawerContent
    nz-form
    [formGroup]="newGroupForm"
    (ngSubmit)="createNewGroup()"
  >
    <h5>{{ "groups.addGroup" | translate }}</h5>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">
        {{ "groups.name" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="
        (newGroupForm.get('name')?.invalid &&
        (newGroupForm.get('name')?.touched || 
         newGroupForm.get('name')?.dirty))
          ? ('groups.nameRequired' | translate) : null
      "
      >
        <input nz-input formControlName="name" id="name" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="path">
        {{ "groups.path" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="
        (newGroupForm.get('path')?.invalid &&
        (newGroupForm.get('path')?.touched || 
         newGroupForm.get('path')?.dirty))
          ? ('groups.pathRequired' | translate) : null
      "
      >
        <input nz-input formControlName="path" id="path" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="parentGroup">
        {{ "groups.parentGroup" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
      >
        <p-dropdown
          id="parentGroup"
          [options]="groups"
          name="parentGroup"
          optionLabel="name"
          optionValue="id"
          [filter]="true"
          filterBy="name"
          [showClear]="false"
          placeholder="choose parent"
          (onChange)="addParent($event)"
          [disabled]="!this.newGroupForm.valid"
          (onFilter)="onFilter($event)"
        ></p-dropdown>
      </nz-form-control>
    </nz-form-item>
  </form>

  <ng-template #footerTpl>
    <div style="float: right">
      <button nz-button style="margin-right: 8px" (click)="close()">
        {{ "groups.cancel" | translate }}
      </button>
      <button nz-button nzType="primary" (click)="createNewGroup()">
        {{ "groups.submit" | translate }}
      </button>
    </div>
  </ng-template>
</nz-drawer>

<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]="false"
  [nzWidth]="500"
  [nzVisible]="visibleEdit"
  nzTitle="Edit Group Data"
  [nzFooter]="footerEdit"
  (nzOnClose)="closeEdit()"
>
  <form
    *nzDrawerContent
    nz-form
    [formGroup]="editGroupForm"
    (ngSubmit)="editGroupData()"
  >
    <h5>{{ "groups.edit" | translate }} {{ selectedNode?.title }}</h5>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">
        {{ "groups.name" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'groups.nameRequired' | translate"
      >
        <input nz-input formControlName="name" id="name" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="path">
        {{ "groups.path" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'groups.pathRequired' | translate"
      >
        <input nz-input formControlName="path" id="path" />
      </nz-form-control>
    </nz-form-item>
  </form>

  <ng-template #footerEdit>
    <div style="float: right">
      <button nz-button style="margin-right: 8px" (click)="closeEdit()">
        {{ "groups.cancel" | translate }}
      </button>
      <button
        type="button"
        nz-button
        nzType="primary"
        (click)="editGroupData()"
      >
        {{ "groups.submit" | translate }}
      </button>
    </div>
  </ng-template>
</nz-drawer>


<!-- =================================drawer to add users into group ================================== -->
<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]="false"
  [nzWidth]="500"
  [nzVisible]="visibleAddUser"
  nzTitle="Benutzer hinzufügen"
  [nzFooter]="footerAddUser"
  (nzOnClose)="closeAddUser()"
>
  <form
    *nzDrawerContent
    nz-form
    [formGroup]="addUserForm"
    (ngSubmit)="addUserToGroup()"
  >
    <h5>Benutzer zur Gruppe hinzufügen {{ selectedNode?.name }}</h5>
    <nz-select
      [nzMaxTagCount]="5"
      nzMode="multiple"
      nzPlaceHolder="Please select"
      [(ngModel)]="listOfSelectedUser"
      [ngModelOptions]="{ standalone: true }"
      [nzMaxTagPlaceholder]="tagPlaceHolder"
    >
      <nz-option
        *ngFor="let item of usersListForSelect"
        [nzLabel]="item?.username"
        [nzValue]="item?.id"
      ></nz-option>
      <ng-template #tagPlaceHolder let-selectedList
        >and {{ selectedList.length }} more selected</ng-template
      >
    </nz-select>
  </form>

  <ng-template #footerAddUser>
    <div style="float: right">
      <button nz-button style="margin-right: 8px" (click)="closeAddUser()">
        Cancel
      </button>
      <button
        type="button"
        nz-button
        nzType="primary"
        (click)="addUserToGroup()"
      >
        Submit
      </button>
    </div>
  </ng-template>
</nz-drawer>
