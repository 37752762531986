import { Component } from '@angular/core';

@Component({
  selector: 'app-un-auth',
  standalone: true,
  imports: [],
  templateUrl: './un-auth.component.html',
  styleUrl: './un-auth.component.css'
})
export class UnAuthComponent {

}
