import { CommonModule, NgFor } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormControl,
  Validators,
  NonNullableFormBuilder,
} from '@angular/forms';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import {
  RoleAttributes,
  RoleData,
  SingleData,
  Attributes,
} from '../../models/data';
import { RolesService } from '../../services/roles.service';
import { KeycloakAdminService } from '../../services/keycloak-admin.service';
import { TranslateModule } from '@ngx-translate/core';
import { LangService } from '../../services/lang.service';
import { IRole } from '../../models/roles';

@Component({
  selector: 'app-roles',
  standalone: true,
  imports: [
    NzTableModule,
    NzDividerModule,
    NgFor,
    NzAffixModule,
    NzButtonModule,
    NzIconModule,
    NzFlexModule,
    NzDrawerModule,
    NzFormModule,
    FormsModule,
    NzInputModule,
    NzSwitchModule,
    NzSelectModule,
    NzMessageModule,
    NzPopconfirmModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
  ],
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.css',
})
export class RolesComponent implements OnInit {
  isUpdate: boolean = false;
  openedRoleId: string = '';
  searchText: string = '';
  allRoles: IRole[] = [];
  roleName: any;
  roleService = inject(RolesService);
  rolesData!: IRole[];
  visible = false;
  pageSize = 10;
  pageNumber = 1;

  attributes = {} as Attributes;
  totalCount!: number;

  ngOnInit() {
    this.loadRoles();
  }
  constructor(
    private fb: NonNullableFormBuilder,
    private message: NzMessageService,
    private langService: LangService
  ) {}

  // implement role form
  newRoleForm: FormGroup<{
    name: FormControl<string>;
    description: FormControl<string>;
  }> = this.fb.group({
    name: ['', [Validators.required]],
    description: ['', [Validators.required]],
  });

  loadRoles() {
    this.roleService.getRoles(this.pageSize, this.pageNumber).subscribe({
      next: (data) => {
        this.rolesData = data.items;
        this.allRoles = data.items;
        this.totalCount = data.totalCount;
      },
    });
  }

  createNewRole() {
    this.isUpdate = false;
    this.newRoleForm.reset();
    this.open();
  }
  editRole(data: any) {
    this.newRoleForm.reset();
    this.isUpdate = true;
    this.open();
    this.openedRoleId = data.id;
    this.roleName = data.name;
    try {
      this.newRoleForm.setValue(data.attributes);
    } catch (e) {}
    // this.newRoleForm.controls.slug.setValue(data.attributes.slug);
    this.newRoleForm.controls.name.setValue(data.name);
    this.newRoleForm.controls.description.setValue(data.description);
  }
  cancel() {}
  confirm(name: string) {
    this.roleService.deleteRoleByName(name).subscribe({
      next: () => {
        this.loadRoles();
        this.createMessage(
          this.langService.lang == 'en'
            ? 'Role has been deleted'
            : 'Rolle wurde gelöscht',
          'success'
        );
      },
      error: (err) => {
        this.createMessage(err.error.message, 'error');
      },
    });

    // this.roleService.deleteRole(id).subscribe({
    //   complete: () => {
    //     this.createMessage('Role has been deleted', 'success');
    //     this.loadRoles();
    //   },
    //   error: (err) => {
    //     this.createMessage(err.statusText, 'error');
    //   },
    // });
  }

  handlePageChange($event: number) {
    this.loadRoles();
  }

  createRole() {
    let roleAttributes: RoleAttributes = this.mapFormToRoleAttributes();
    if(!this.newRoleForm.valid) { this.newRoleForm.markAllAsTouched(); return}
    const roleData: RoleData = {
      attributes: roleAttributes,
      type: 'roles',
      id: roleAttributes.slug,
    };
    const data: SingleData = {
      data: roleData,
    };
    if (this.isUpdate) {
      data.data.id = this.openedRoleId;
      this.roleService
        .updateRoleData(this.roleName, this.newRoleForm.value)
        .subscribe({
          next: (data) => {
            this.createMessage(
              this.langService.lang == 'en'
                ? 'Role has been updated'
                : 'Rolle wurde aktualisiert',
              'success'
            );
          },
          complete: () => {
            this.loadRoles();
            this.close();
            this.newRoleForm.reset();
          },
        });
    } else {
      this.roleService.addNewRole(this.newRoleForm.value).subscribe({
        next: (data) => {
          this.createMessage(
            this.langService.lang == 'en'
              ? 'Role was added'
              : 'Rolle wurde hinzugefügt',
            'success'
          );
        },
        complete: () => {
          this.loadRoles();
          this.close();
          this.newRoleForm.reset();
        },
        error: (err) => {
          this.createMessage(err.error.message, 'error');
        },
      });
      // this.kcAdminService
      //   .addReleamRoles(this.newRoleForm.value)
      //   .then((data) => {
      //     this.loadRoles();
      //     this.close();
      //     this.newRoleForm.reset();
      //   })
      //   .catch((err) => {
      //     this.loadRoles();
      //     this.close();
      //     this.newRoleForm.reset();
      //     if (err?.response?.status == 409) {
      //       this.createMessage(
      //         `Sibling role named ${this.newRoleForm.value.name} already exists.`,
      //         'error'
      //       );
      //     }
      //   });
    }
  }
  createMessage(message: string, type: string): void {
    this.message.create(type, `${message}`);
  }

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  mapFormToRoleAttributes(): RoleAttributes {
    return {
      slug: this.newRoleForm.get('slug')?.value,
      name: {
        de: this.newRoleForm.get('name')?.value,
        en: this.newRoleForm.get('name')?.value,
      },
      description: {
        de: this.newRoleForm.get('description')?.value,
        en: this.newRoleForm.get('description')?.value,
      },
    };
  }

  searchResult() {
    if (this.searchText?.length) {
      // this.rolesData = this.allRoles.filter((app: any) => {
      //   return app.name?.toLowerCase().includes(this.searchText.toLowerCase());
      // });
      this.roleService.getRolesBySearch(this.searchText).subscribe({
        next: (value) => {
          this.rolesData = value.items;
          this.totalCount = value.totalCount;
        },
      });
    } else {
      this.loadRoles()
    }
  }
  clearSearch() {
    this.searchText = '';
    this.loadRoles()
  }

  highlightText(text: string, search: string): string {
    if (!search) {
      return text;
    }
    const re = new RegExp(search, 'gi');
    return text.replace(
      re,
      (match) => `<span class="highlight">${match}</span>`
    );
  }

  onPageIndexChange(page: number): void {
    this.pageNumber = page;
    this.loadRoles();
  }

  onPageSizeChange(size: number): void {
    this.pageSize = size;
    this.pageNumber = 1; // reset to first page
    this.loadRoles();
  }
}
