import { Component, OnInit, inject } from '@angular/core';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTreeModule, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzListModule } from 'ng-zorro-antd/list';
import { NgFor, NgIf } from '@angular/common';
import { KeycloakAdminService } from '../../services/keycloak-admin.service';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { DropdownModule } from 'primeng/dropdown';
import { RolesService } from '../../services/roles.service';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { filterFormValues } from '../../models/form-utility';
import { RouterLink } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { NzTableModule } from 'ng-zorro-antd/table';
import { TreeNode } from 'primeng/api';
import { TreeNodeInterface } from '../../models/treeNode';
import { GroupsService } from '../../services/groups.service';
import { IGroup } from '../../models/group';
import { TranslateModule } from '@ngx-translate/core';
import { LangService } from '../../services/lang.service';

@Component({
  selector: 'app-groups',
  standalone: true,
  imports: [
    NzTreeModule,
    NzGridModule,
    NzAffixModule,
    NzCardModule,
    NzListModule,
    NgIf,
    NgFor,
    NzIconModule,
    NzDrawerModule,
    NzButtonModule,
    ReactiveFormsModule,
    NzFormModule,
    NzPopconfirmModule,
    RouterLink,
    FormsModule,
    NzInputModule,
    NzSwitchModule,
    NzSelectModule,
    NzFlexModule,
    DropdownModule,
    NzTreeModule,
    NzTableModule,
    TranslateModule,
  ],
  templateUrl: './groups.component.html',
  styleUrl: './groups.component.css',
})
export class GroupsComponent implements OnInit {
  nodes: any[] = [];
  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};
  selectedNode: any;
  users: any[] = [];
  roles: any[] | undefined = [];
  visible: boolean = false;
  newGroupRoles: any[] | undefined = [];
  groups: IGroup[] = [];
  allgroupData!: IGroup[];
  updatedSubGroup: any;
  visibleEdit: boolean = false;
  editableGroup: any;
  visibleAddUser: boolean = false;
  listOfSelectedUser: any;
  usersList: any;
  usersListForSelect: any;
  searchValue = '';
  searchValue$ = new BehaviorSubject<string>('');
  originGroups: any;
  filteredNodes!: any[];
  totalCount!: number;
  pageNumber: number = 1;
  pageSize: number = 10;

  constructor(
    private fb: NonNullableFormBuilder,
    private message: NzMessageService,
    private groupService: GroupsService,
    private langService: LangService
  ) {}
  async ngOnInit() {
    this.loadGroups();
  }

  cancel() {}

  filterNodes(): void {
    if (!this.searchValue) {
      this.loadGroups();
    } else {
      // this.filteredNodes = this.filterTree(
      //   this.nodes,
      //   this.searchValue.toLowerCase()
      // );
      // this.filteredNodes.forEach((item) => {
      //   this.mapOfExpandedData[item.key] = this.convertTreeToList(item, true);
      // });
      this.groupService.getGroupsBySearch(this.searchValue).subscribe({
        next: (groups) => {
          this.totalCount = groups.totalCount;
          this.customizeGroups(groups.items);
          this.nodes = this.mapGroupsToTreeNodes(groups.items);
        },
        complete: () => {
          this.nodes.forEach((item: any) => {
            this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
          });

          this.filteredNodes = this.nodes;
          this.filteredNodes.forEach((item) => {
            this.mapOfExpandedData[item.key] = this.convertTreeToList(
              item,
              true
            );
          });
        },
      });
    }
    // if (this.searchValue.length == 0) {
    //   this.filteredNodes = this.nodes;
    //   this.filteredNodes.forEach((node) => {
    //     this.mapOfExpandedData[node.key] = this.convertTreeToList(node);
    //   });
    // }
  }

  filterTree(nodes: any[], searchValue: string): TreeNode[] {
    return nodes.filter((node) => {
      const matches = node.name.toLowerCase().includes(searchValue);
      if (node.children) {
        const filteredChildren = this.filterTree(node.children, searchValue);
        if (filteredChildren.length > 0 || matches) {
          node.children = filteredChildren;
          node.expand = true;
          return true;
        }
      }
      return matches;
    });
  }

  confirm(groupId: any) {
    this.groupService.deleteGroup(groupId).subscribe({
      next: (data) => {
        this.loadGroups();
        this.selectedNode = null;
      },
      error: (err) => {
        this.createMessage(err.error.message, 'error');
      },
    });
  }
  highlightText(text: string, search: string): string {
    if (!search) {
      return text;
    }
    const re = new RegExp(search, 'gi');
    return text.replace(
      re,
      (match) => `<span class="highlight">${match}</span>`
    );
  }
  loadGroups() {
    this.groupService.getGroups(this.pageSize, this.pageNumber).subscribe({
      next: (groups) => {
        this.allgroupData = groups.items;
        this.originGroups = groups.items;
        this.groups = [];
        this.totalCount = groups.totalCount;
        this.customizeGroups(groups.items);
        this.nodes = this.mapGroupsToTreeNodes(groups.items);
      },
      error: (err) => {
        this.createMessage(err.error.message, 'error');
      },
      complete: () => {
        this.nodes.forEach((item: any) => {
          this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
        });

        this.filteredNodes = this.nodes;
      },
    });
  }
  addUserForm: FormGroup = this.fb.group({});
  newGroupForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    path: ['', [Validators.required]],
  });
  editGroupForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    path: ['', [Validators.required]],
  });
  get subGroups(): FormArray {
    return this.newGroupForm.get('subGroups') as FormArray;
  }

  getFormArray(control: AbstractControl | null): FormArray {
    return control as FormArray;
  }

  createSubGroup(): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required]],
      path: ['', [Validators.required]],
      access: [null, [Validators.required]],
      realmRoles: [null, Validators.required],
      clientRoles: [null, Validators.required],
      attributes: [null, Validators.required],
      subGroups: this.fb.array([]),
    });
  }

  addSubGroup() {
    this.subGroups.push(this.createSubGroup());
  }

  addNestedSubGroup(subGroups: FormArray) {
    subGroups.push(this.createSubGroup());
  }

  removeSubGroup(subGroups: FormArray, index: number) {
    subGroups.removeAt(index);
  }

  mapGroupsToTreeNodes(groups: any[]): any[] {
    return groups.map((group) => ({
      name: group.name,
      key: group.id,
      expand: false,
      children: this.mapGroupsToTreeNodes(group.subGroups || []),
    }));
  }

  customizeGroups(groups: any[]) {    
    groups.forEach((group) => {
      this.groups.push(group);
      if (group?.subGroups?.length > 0) this.customizeGroups(group.subGroups);
    });
  }
  addParent(subGroupEvent: any) {
    let subGroupId = subGroupEvent?.value;
    let subgroup = this.groups.filter((group) => {
      return group.id == subGroupId;
    });
    subgroup[0].subGroups.push(this.newGroupForm.value);
    this.updatedSubGroup = subgroup[0];
  }

  createCategory() {
    this.visible = true;
  }
  openEdit() {
    this.visibleEdit = true;
  }

  closeEdit() {
    this.visibleEdit = false;
  }
  setEditableGroup(group: any) {
    let editableGroup = this.groups.filter((data: any) => {
      return data?.id == group.key;
    });
    this.openEdit();
    const filtered = filterFormValues(this.editGroupForm, editableGroup[0]);
    this.editGroupForm.setValue(filtered);
    this.editableGroup = editableGroup[0];
  }
   editGroupData() {
    this.groupService
      .editGroupData(this.editableGroup.id, this.editGroupForm.value)
      .subscribe({
        next: (data) => {
          this.createMessage(
            this.langService.lang == 'en'
              ? 'Group has been updated'
              : 'Die Gruppe wurde aktualisiert',
            'success'
          );
        },
        error: (error) => {},
        complete: () => {
          this.editGroupForm.reset();
          this.selectedNode = null;
          this.closeEdit();
          this.loadGroups();
        },
      });
  }
   createNewGroup() {
    if (!this.newGroupForm.valid) {
      this.newGroupForm.markAllAsTouched();
      return};
    if (this.updatedSubGroup) {
      // console.log(this.updatedSubGroup);

       this.groupService
        .addSubGroup(this.updatedSubGroup?.id, this.newGroupForm.value)
        .subscribe({
          next: (data) => {
            this.createMessage(
              this.langService.lang == 'en'
                ? 'Group was added'
                : 'Gruppe wurde hinzugefügt',
              'success'
            );
          },
          error: (error) => {},
          complete: () => {
            this.newGroupForm.reset();
            this.close();
            this.loadGroups();
          },
        });
    } else {
      this.groupService.addGroup(this.newGroupForm.value).subscribe({
        next: (data) => {
          this.createMessage(
            this.langService.lang == 'en'
              ? 'Group was added'
              : 'Gruppe wurde hinzugefügt',
            'success'
          );
        },
        error: (error) => {},
        complete: () => {
          this.newGroupForm.reset();
          this.close();
          this.loadGroups();
        },
      });
    }
  }

  addUserToGroup() {}
  open(): void {
    this.visible = true;
  }

  close(): void {
    this.newGroupForm.reset();
    this.visible = false;
  }

  createMessage(message: string, type: string): void {
    this.message.create(type, `${message}`);
  }

  closeAddUser() {
    this.visibleAddUser = false;
  }

  clearSearch() {
    // this.filteredNodes = this.nodes;
    this.searchValue = '';
    // this.filteredNodes.forEach((node) => {
    //   this.mapOfExpandedData[node.key] = this.convertTreeToList(node);
    // });
    this.loadGroups();
  }
  collapse(
    array: TreeNodeInterface[],
    data: TreeNodeInterface,
    $event: boolean
  ): void {
    if (!$event) {
      if (data.children) {
        data.children.forEach((d) => {
          const target = array.find((a) => a.key === d.key)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }

  convertTreeToList(
    root: TreeNodeInterface,
    expand: boolean = false
  ): TreeNodeInterface[] {
    const stack: TreeNodeInterface[] = [];
    const array: TreeNodeInterface[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: expand });

    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({
            ...node.children[i],
            level: node.level! + 1,
            expand: expand,
            parent: node,
          });
        }
      }
    }

    return array;
  }

  visitNode(
    node: TreeNodeInterface,
    hashMap: { [key: string]: boolean },
    array: TreeNodeInterface[]
  ): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }

  onPageIndexChange(page: number): void {
    this.pageNumber = page;
    this.loadGroups();
  }

  onPageSizeChange(size: number): void {
    this.pageSize = size;
    this.pageNumber = 1; // reset to first page
    this.loadGroups();
  }

  onFilter(event: any): void {
    // this.searchValue = event;
    // this.loadGroups();
    if (event?.filter) {
      this.groupService.getGroupsBySearch(event?.filter).subscribe({
        next: (data) => {
          this.groups=[]
          this.customizeGroups(data.items);
        },
      });
    }
  }
}
