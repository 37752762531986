import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment.development';
import { GroupList, IGroup } from '../models/group';
import { User, UserList } from '../models/user';
import { UserData } from '../models/data';
import { IRole } from '../models/roles';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  private http = inject(HttpClient);

  constructor() {}

  //get all groups
  getAllGroups() {
    return this.http.get<IGroup[]>(environment.userApis + '/groups/all');
  }
  getGroups(size: number, page: number) {
    return this.http.get<GroupList>(
      environment.userApis + '/groups' + `?size=${size}&page=${page}`
    );
  }

  getGroupsBySearch(searchValue: string) {
    return this.http.get<GroupList>(
      environment.userApis + '/groups' + `?search=${searchValue}`
    );
  }

  getGroupById(id: string) {
    return this.http.get<GroupList>(
      environment.userApis + '/groups' + `/${id}`
    );
  }

  getGroupMembers(id: string) {
    return this.http.get<User[]>(
      environment.userApis + '/groups' + `/${id}/members`
    );
  }

  addMembersToGroup(groupId: string, userIds: string[]) {
    return this.http.post<UserList>(
      environment.userApis + '/groups' + `/${groupId}/members`,
      userIds
    );
  }

  editGroupData(id: number, group: any) {
    return this.http.put<GroupList>(
      environment.userApis + '/groups/' + id,
      group
    );
  }

  addGroup(group: any) {
    return this.http.post<GroupList>(environment.userApis + '/groups', group);
  }

  deleteGroup(id: string) {
    return this.http.delete(environment.userApis + '/groups/' + id);
  }

  addSubGroup(parentGroupId: string, subGroup: any) {
    return this.http.post<GroupList>(
      environment.userApis + '/groups/' + parentGroupId + '/children',
      subGroup
    );
  }

  getGroupRoles(groupId: string) {
    return this.http.get<IRole[]>(
      environment.userApis + '/groups/' + groupId + '/roles'
    );
  }

  deleteRoleFromGroup(groupId: string, role: any) {
    return this.http.delete(
      environment.userApis + '/groups/' + groupId + '/roles',
      {
        body: role,
      }
    );
  }

  addRoleToGroup(groupId: string, roles: any) {
    return this.http.post<GroupList>(
      environment.userApis + '/groups/' + groupId + '/roles',
      roles
    );
  }
}
